import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import PowerSettingsNewIcon from '@mui/icons-material/PowerSettingsNew';
import QuickreplyIcon from '@mui/icons-material/Quickreply';
import ShareIcon from '@mui/icons-material/Share';
import {
    Alert,
    Box,
    Button,
    Grid,
    IconButton,
    MenuItem,
    Select,
    Skeleton,
    Stack,
    Switch,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useContext, useEffect, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import PageContainer from '../../../components/common/PageContainter';
import StyledCard from '../../../components/common/StyledCard';
import StyledCardTitleBar from '../../../components/common/StyledCardTitleBar';
import MessageUsersAutoMassDmDescription from '../../../components/services/messageUsers/autoMassDm/MessageUsersAutoMassDmDescription';
import MessageUsersAutoMassDmListTableRow from '../../../components/services/messageUsers/autoMassDm/MessageUsersAutoMassDmListTableRow';
import MessageUsersAutoMassDmQueueList from '../../../components/services/messageUsers/autoMassDm/MessageUsersAutoMassDmQueueList';
import MessageUsersAutoMassDmSearchBar from '../../../components/services/messageUsers/autoMassDm/MessageUsersAutoMassDmSearchBar';
import SextforceAutoRepostNewRepost from '../../../components/services/sextforce/autoRepost/SextforceAutoRepostNewRepost';
import useMessageUsersMassDm, {
    SextforceAutoMassDmMessageListSortBy,
    SextforceAutoMassDmMessageStatus,
} from '../../../hooks/useMessageUsersMassDm';
import useSubscriber from '../../../hooks/useSubscriber';
import useSubscriberMassDmQueue from '../../../hooks/useSubscriberMassDmQueue';
import { SettingsContext } from '../../../store/SettingsContext';

export const TablePaginationActions = (props: {
    count: number;
    limit: number;
    setLimit: (limit: number) => void;
    skip: number;
    setSkip: (skip: number) => void;
}) => {
    const { count, limit, setLimit, skip, setSkip } = props;

    const handleNextButtonClick = () => {
        setSkip(skip + limit);
    };

    const handleBackButtonClick = () => {
        setSkip(skip - limit);
    };

    return (
        <Stack
            direction="row"
            spacing={2}
            alignItems={'center'}
            justifyContent={'flex-end'}
            width={'100%'}
            sx={{
                mt: 2,
            }}
        >
            <Box sx={{ flex: '1 1 auto' }} />
            <Typography variant="body2">Rows per page:</Typography>
            <Select
                variant="standard"
                value={limit}
                onChange={e => {
                    setLimit(parseInt(e.target.value as string));
                    setSkip(0);
                }}
                size="small"
                sx={{ width: 75 }}
            >
                {[10, 20, 50, 100].map(option => (
                    <MenuItem key={option} value={option}>
                        {option}
                    </MenuItem>
                ))}
            </Select>
            <Typography variant="body2">
                {(skip + 1).toLocaleString()}-{Math.min(skip + limit, count).toLocaleString()} of {count.toLocaleString()}
            </Typography>
            <Box>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                    <IconButton onClick={handleBackButtonClick} disabled={skip === 0}>
                        <NavigateBeforeIcon />
                    </IconButton>
                    <IconButton onClick={handleNextButtonClick} disabled={skip + limit >= count}>
                        <NavigateNextIcon />
                    </IconButton>
                </Box>
            </Box>
        </Stack>
    );
};

const MessageUsersAutoMassDm = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();
    const timezone: string = moment.tz.guess();

    const [serviceActive, setServiceActive] = useState<boolean>(false);
    const [sendSuccessNotification, setSendSuccessNotification] = useState<boolean>(false);

    const [active, setActive] = useState<boolean>(false);

    const [query, setQuery] = useState<string>('');
    const [status, setStatus] = useState<SextforceAutoMassDmMessageStatus>('sent');
    const [offset, setOffset] = useState<number>(0);
    const [limit, setLimit] = useState<number>(20);
    const [sortBy, setSortBy] = useState<SextforceAutoMassDmMessageListSortBy>('date');
    const {
        data: massDms,
        isLoading: isMassDmsLoading,
        refetch: refetchMassDms,
    } = useMessageUsersMassDm(query, status, offset, limit, sortBy);
    const [showAddNewForm, setShowAddNewForm] = useState<boolean>(false);

    const {
        data: subscriber,
        isLoading: isSubscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettings,
    } = useSubscriber();

    const {
        data: massDmQueue,
        isLoading: isMassDmQueueLoading,
        refetch: refetchMassDmQueue,
    } = useSubscriberMassDmQueue(subscriber && '_id' in subscriber ? true : false);

    useEffect(() => {
        if (subscriber && subscriber.sextforce) {
            if (subscriber.sextforce.autoMassDm) {
                setServiceActive(
                    'active' in subscriber.sextforce.autoMassDm && typeof subscriber.sextforce.autoMassDm.active === 'boolean'
                        ? subscriber.sextforce.autoMassDm.active
                        : true,
                );
                setSendSuccessNotification(
                    'sendSuccessNotification' in subscriber.sextforce.autoMassDm &&
                        typeof subscriber.sextforce.autoMassDm.sendSuccessNotification === 'boolean'
                        ? subscriber.sextforce.autoMassDm.sendSuccessNotification
                        : true,
                );
            } else {
                setServiceActive(true);
                setSendSuccessNotification(true);
            }
        } else {
            setServiceActive(false);
            setSendSuccessNotification(true);
        }
    }, [subscriber]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ mb: 2 }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Auto Mass DM for {subscriber.username}
                    </Typography>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar title="Auto Mass DM" theme={theme} />
                    </StyledCard>

                    <StyledCard>
                        <MessageUsersAutoMassDmDescription />
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                Auto Mass DM is currently{' '}
                                {serviceActive ? (
                                    <span style={{ color: theme.palette.success.dark }}>running</span>
                                ) : (
                                    <span style={{ color: theme.palette.error.main }}>not running</span>
                                )}
                                .
                            </Grid>
                            <Grid item xs="auto">
                                {isSubscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={'115px'} height={'51px'} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={serviceActive}
                                        onChange={e => {
                                            setServiceRunning('sextforce.autoMassDm', e.target.checked);
                                            setActive(e.target.checked);
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                            <Grid item xs={12}>
                                <Stack direction={'row'} spacing={1} alignItems="center" justifyContent={'space-between'}>
                                    <Stack direction={'column'} spacing={1} alignItems="flex-start">
                                        <Typography variant="body1">Send notification on Telegram for successful message sent?</Typography>
                                        <Typography variant="body2" color="textSecondary">
                                            If enabled, the system will send a notification to your Telegram account when a Mass DM is
                                            successfully sent out. If you have a lot of Mass DMs scheduled, this can be annoying and you
                                            might prefer to turn these off. It will still send a notification if the Mass DM fails to send
                                            for any reason.
                                        </Typography>
                                    </Stack>
                                    {isSubscriberLoading || setServiceRunningLoading ? (
                                        <Skeleton width={'60px'} variant="text" />
                                    ) : (
                                        <Switch
                                            checked={sendSuccessNotification}
                                            size="medium"
                                            onChange={e => {
                                                setServiceSettings('sextforce.autoRepost', {
                                                    active: serviceActive,
                                                    sendSuccessNotification: e.target.checked,
                                                });
                                            }}
                                        />
                                    )}
                                </Stack>
                            </Grid>
                        </Grid>
                    </StyledCard>

                    {!showAddNewForm && (
                        <StyledCard noCard>
                            <Button
                                variant="contained"
                                color="secondary"
                                fullWidth
                                size="large"
                                onClick={() => {
                                    setShowAddNewForm(true);
                                }}
                            >
                                Add New Mass DM
                            </Button>
                        </StyledCard>
                    )}

                    {showAddNewForm && (
                        <SextforceAutoRepostNewRepost
                            subscriber={subscriber}
                            refetchAutoReposts={refetchMassDmQueue}
                            setShowAddNewForm={setShowAddNewForm}
                            handleCancel={() => {
                                setShowAddNewForm(false);
                            }}
                        />
                    )}

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar
                            title={`Currently Sending Mass DMs (${massDmQueue ? massDmQueue.length.toLocaleString() : 0})`}
                            isLoading={isMassDmQueueLoading}
                            theme={theme}
                        />
                    </StyledCard>
                    <StyledCard
                        sx={{
                            paddingLeft: 0,
                            paddingRight: 0,
                        }}
                    >
                        {!massDmQueue || massDmQueue.length === 0 ? (
                            <Typography variant="body2" color="text.secondary" textAlign="center">
                                No Mass DMs currently sending
                            </Typography>
                        ) : null}
                        {massDmQueue && massDmQueue.length > 0 && (
                            <MessageUsersAutoMassDmQueueList massDmQueue={massDmQueue} refetchMassDmQueue={refetchMassDmQueue} />
                        )}
                    </StyledCard>

                    <StyledCard noCard noBottomMargin>
                        <StyledCardTitleBar
                            title={`OnlyFans Mass DMs (${massDms && massDms.total ? massDms.total.toLocaleString() : 0})`}
                            isLoading={isMassDmsLoading}
                            theme={theme}
                        />
                    </StyledCard>

                    <MessageUsersAutoMassDmSearchBar
                        massDmsLoading={isMassDmsLoading}
                        status={status}
                        setStatus={setStatus}
                        setQuery={setQuery}
                        sortBy={sortBy}
                        setSortBy={setSortBy}
                        theme={theme}
                    />
                    <StyledCard>
                        <Alert severity="info" icon={false} sx={{ mb: 1 }}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs={12}>
                                    Click on the rows below to see the statistics of each repost. If the repost is not getting enough views,
                                    decrease the frequency of the reposting.
                                </Grid>
                                <Grid item xs={12}>
                                    <Stack direction="column" spacing={0}>
                                        <Typography variant="body2" fontWeight="bold">
                                            Actions
                                        </Typography>
                                        <Stack direction="row" spacing={1}>
                                            <MoreTimeIcon fontSize="small" color="success" />
                                            <Typography variant="body2">Change Next Auto Repost Date & Time</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <EditIcon fontSize="small" color="primary" />
                                            <Typography variant="body2">Edit Auto Repost Settings</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <QuickreplyIcon fontSize="small" color="info" />
                                            <Typography variant="body2">
                                                Setup Auto-Reply to Campaign Tip for this post and reposts
                                            </Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <ShareIcon fontSize="small" color="secondary" />
                                            <Typography variant="body2">Share Auto Repost Updates & Stats</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <DeleteIcon fontSize="small" color="error" />
                                            <Typography variant="body2">Delete Auto Repost</Typography>
                                        </Stack>
                                        <Stack direction="row" spacing={1}>
                                            <PowerSettingsNewIcon fontSize="small" color="success" />
                                            <Typography variant="body2">Reactivate Auto Repost</Typography>
                                        </Stack>
                                    </Stack>
                                </Grid>
                            </Grid>
                        </Alert>

                        {!isMassDmsLoading && massDms && massDms.list && massDms.list.length === 0 && (
                            <Typography
                                variant="body1"
                                sx={{
                                    textAlign: 'center',
                                    color: theme.palette.text.secondary,
                                    mt: 1,
                                }}
                            >
                                No Mass DMs found
                                <br />
                                Click on the button above to create a new Mass DM
                            </Typography>
                        )}
                        {!isMassDmsLoading && massDms && massDms.list.length > 0 && (
                            <>
                                <Box
                                    sx={{
                                        display: 'block',
                                        overflowX: 'auto',
                                    }}
                                >
                                    <Table
                                        size="small"
                                        sx={{
                                            // No padding on the left and right of the table but only on the first and last cell
                                            '& td': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                            '& th': {
                                                '&:first-of-type': {
                                                    paddingLeft: '0px',
                                                    paddingRight: '0px',
                                                },
                                                '&:last-child': {
                                                    paddingRight: '0px',
                                                },
                                                // Make header cells bold
                                                fontWeight: 'bold',
                                                paddingLeft: 0.5,
                                                paddingRight: 0.5,
                                            },
                                        }}
                                    >
                                        <TableHead>
                                            <TableRow>
                                                <TableCell width={120}>Date</TableCell>
                                                <TableCell>Name</TableCell>
                                                <TableCell width={70} align="center">
                                                    Sent
                                                </TableCell>
                                                <TableCell width={70} align="center">
                                                    Viewed
                                                </TableCell>
                                                <TableCell width={100} align="center">
                                                    Purchased
                                                </TableCell>
                                                <TableCell width={125} align="center">
                                                    Actions
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {massDms.list.map(massDm => (
                                                <MessageUsersAutoMassDmListTableRow
                                                    subscriber={subscriber}
                                                    massDm={massDm}
                                                    refetchMassDms={refetchMassDms}
                                                    key={massDm._id}
                                                />
                                            ))}
                                        </TableBody>
                                    </Table>
                                </Box>
                                <TablePaginationActions
                                    count={massDms.total}
                                    limit={limit}
                                    setLimit={setLimit}
                                    skip={offset}
                                    setSkip={setOffset}
                                />
                            </>
                        )}
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default MessageUsersAutoMassDm;

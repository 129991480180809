import AddIcon from '@mui/icons-material/Add';
import DeleteIcon from '@mui/icons-material/Delete';
import LoadingButton from '@mui/lab/LoadingButton';
import { Alert, Button, Grid, Skeleton, Stack, Theme, Typography, useTheme } from '@mui/material';
import { useDialog } from 'muibox';
import { useContext, useEffect, useMemo, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import LargeMaterialUISwitch from '../../../components/common/LargeMaterialUISwitch';
import PageContainer from '../../../components/common/PageContainter';
import StyledCard from '../../../components/common/StyledCard';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';
import { OnlyFansSubscriberHostessAutoReplyExpired } from '../../../types/hostessAutoReplyFreeloaders';
import { isSextforceActive, stripHtmlAndConvertParagraphs } from '../../../utils/common';
import AutoReplyToExpiredIntroCard from './components/AutoReplyToExpiredIntroCard';
import AutoReplyToExpiredSettingsCard from './components/AutoReplyToExpiredSettingsCard';
import FormAutoReplyMessageEditor from './components/FormAutoReplyMessageEditor';

const HostessAutoReplyToExpired = () => {
    const settingsContext = useContext(SettingsContext);
    const {
        data: subscriber,
        isFetching: subscriberLoading,
        setServiceRunning,
        setServiceRunningLoading,
        setServiceSettingsHostessAutoReplyExpired,
        setServiceSettingsHostessAutoReplyExpiredLoading,
    } = useSubscriber();
    const theme: Theme = useTheme();
    const dialog = useDialog();

    const autoReplyExpired: OnlyFansSubscriberHostessAutoReplyExpired | null = useMemo(() => {
        return subscriber && subscriber.hostess && subscriber.hostess.autoReply && subscriber.hostess.autoReply.expired
            ? subscriber.hostess.autoReply.expired
            : null;
    }, [subscriber]);

    const [formData, setFormData] = useState<OnlyFansSubscriberHostessAutoReplyExpired>(
        autoReplyExpired ||
            ({
                action: 'mute',
                delayBeforeSendingMessage: false,
                active: false,
                autoUnrestrict: true,
                excludeCreators: true,
                sendReplyOnPurchase: false,
                skipUsersnames: [],
                replies: [],
                repeatScriptWhenFinished: false,
            } as OnlyFansSubscriberHostessAutoReplyExpired),
    );
    const [autoReplyExpiredRunning, setAutoReplyExpiredServiceRunning] = useState<boolean>(false);

    // Keep track which messages have restricted words with an array of booleans corresponding to each message
    const [messageHasRestrictedWords, setMessageHasRestrictedWords] = useState<boolean[]>([]);

    useEffect(() => {
        if (autoReplyExpired && subscriber) {
            setFormData({
                ...formData,
                ...autoReplyExpired,
                replies: autoReplyExpired.replies.map(reply => {
                    return stripHtmlAndConvertParagraphs(reply);
                }),
            });

            setAutoReplyExpiredServiceRunning(autoReplyExpired.active && isSextforceActive(subscriber) ? true : false);
        } else {
            setAutoReplyExpiredServiceRunning(false);
        }
    }, [autoReplyExpired, subscriber]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: theme.spacing(2) }}>
                        <BackNavigationButton url={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}`} />{' '}
                        Hostess Auto Reply to Expired for {subscriber.username}
                    </Typography>

                    <AutoReplyToExpiredIntroCard />

                    {!subscriber.isLoggedIn && (
                        <Alert severity="error" sx={{ marginBottom: theme.spacing(2) }}>
                            We need to relogin to this account
                            <br />
                            Please contact Admin
                        </Alert>
                    )}

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                <Stack direction="column" spacing={0}>
                                    <div>
                                        Auto Reply to Expired is currently{' '}
                                        {autoReplyExpiredRunning ? (
                                            <span style={{ color: theme.palette.success.dark }}>running</span>
                                        ) : (
                                            <span style={{ color: theme.palette.error.main }}>not running</span>
                                        )}
                                        .
                                    </div>
                                    {formData.replies.length === 0 && (
                                        <div>
                                            <Typography variant="body2" color={theme.palette.error.main}>
                                                You need to add at least one reply to enable this service
                                            </Typography>
                                        </div>
                                    )}
                                </Stack>
                            </Grid>
                            <Grid item xs="auto">
                                {subscriberLoading || setServiceRunningLoading ? (
                                    <Skeleton width={87} height={51} />
                                ) : (
                                    <LargeMaterialUISwitch
                                        checked={autoReplyExpiredRunning}
                                        disabled={!autoReplyExpired || !autoReplyExpired.replies || !autoReplyExpired.replies.length}
                                        onChange={e => {
                                            setServiceRunning('hostess.autoReply.expired', e.target.checked);
                                            // setAutoReplyFreeloadersServiceRunning(e.target.checked)
                                        }}
                                        color="primary"
                                    />
                                )}
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <AutoReplyToExpiredSettingsCard formData={formData} setFormData={setFormData} autoReplyExpired={autoReplyExpired} />

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                            <Grid item xs={12}>
                                <Typography variant="h6">Replies</Typography>
                            </Grid>
                            <Grid item xs>
                                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                                    These are the automatic replies the system will send to the expired fans if they match the above
                                    criteria. You can have setup as many replies as you like. On the last reply, the system will take the
                                    action you specified above. Try to nudge the expired fans <strong>politely</strong> towards
                                    resubscribing or following you again.
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                            {formData && formData.replies && formData.replies.length > 0 ? (
                                formData.replies.map((item, index) => (
                                    <Grid item xs={12} key={index}>
                                        <Grid container spacing={0} flexGrow={0} alignItems="center" justifyContent="center" sx={{ mb: 2 }}>
                                            <Grid item xs>
                                                <Typography variant="body1">Reply {index + 1}</Typography>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Button
                                                    variant="contained"
                                                    color="primary"
                                                    sx={{ marginTop: theme.spacing(1), marginRight: theme.spacing(1) }}
                                                    onClick={() => {
                                                        const replies = formData.replies;
                                                        replies.splice(index, 0, '');
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                >
                                                    Insert Above Reply {index + 1}
                                                </Button>
                                            </Grid>
                                            <Grid item xs="auto">
                                                <Grid item xs="auto">
                                                    <Button
                                                        variant="contained"
                                                        color="error"
                                                        startIcon={<DeleteIcon />}
                                                        sx={{ marginTop: theme.spacing(1) }}
                                                        onClick={() => {
                                                            dialog
                                                                .confirm({
                                                                    title: 'Delete Reply',
                                                                    message: `Are you sure you want to delete this reply ${index + 1}?`,
                                                                })
                                                                .then(result => {
                                                                    const replies = formData.replies;
                                                                    replies.splice(index, 1);
                                                                    setFormData({ ...formData, replies: replies });
                                                                })
                                                                .catch(() => {});
                                                        }}
                                                    >
                                                        Delete Reply {index + 1}
                                                    </Button>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12}>
                                                <FormAutoReplyMessageEditor
                                                    message={item}
                                                    setMessage={message => {
                                                        const replies = formData.replies;
                                                        replies[index] = message;
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                    messageHasRestrictedWords={messageHasRestrictedWords[index]}
                                                    setMessageHasRestrictedWords={messageHasRestrictedWord => {
                                                        const messageHasRestrictedWordsCopy = messageHasRestrictedWords;
                                                        messageHasRestrictedWordsCopy[index] = messageHasRestrictedWord;
                                                        setMessageHasRestrictedWords(messageHasRestrictedWordsCopy);
                                                    }}
                                                />
                                                {/* <OnlyFansMessageEditor
                                                    message={item}
                                                    setMessage={message => {
                                                        const replies = formData.replies;
                                                        replies[index] = message;
                                                        setFormData({ ...formData, replies: replies });
                                                    }}
                                                    messageHasRestrictedWords={messageHasRestrictedWords[index]}
                                                    setMessageHasRestrictedWords={messageHasRestrictedWord => {
                                                        const messageHasRestrictedWordsCopy = messageHasRestrictedWords;
                                                        messageHasRestrictedWordsCopy[index] = messageHasRestrictedWord;
                                                        setMessageHasRestrictedWords(messageHasRestrictedWordsCopy);
                                                    }}
                                                    height={100}
                                                /> */}
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                ))
                            ) : (
                                <Grid item xs={12}>
                                    <Typography variant="body1">No replies</Typography>
                                </Grid>
                            )}
                            <Grid item xs={12}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    startIcon={<AddIcon />}
                                    sx={{ marginTop: theme.spacing(1) }}
                                    onClick={() => {
                                        const replies = formData.replies;
                                        replies.push('');
                                        setFormData({ ...formData, replies: replies });
                                    }}
                                >
                                    Add Reply
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>

                    <StyledCard>
                        <Grid container spacing={1} flexGrow={0} alignItems="center" justifyContent="center">
                            <Grid item xs>
                                <LoadingButton
                                    variant="contained"
                                    color="primary"
                                    size="large"
                                    loading={setServiceSettingsHostessAutoReplyExpiredLoading}
                                    disabled={
                                        !formData ||
                                        // (formData && formData.replies && formData.replies.length === 0) ||
                                        (formData && formData.replies && formData.replies.some(item => item === ''))
                                    }
                                    onClick={() => {
                                        // Are any of the replies empty?
                                        if (formData) {
                                            // Save the form data
                                            setServiceSettingsHostessAutoReplyExpired(formData).then(data => {
                                                if (data) {
                                                    dialog
                                                        .alert({
                                                            title: 'Success',
                                                            message: 'Settings saved successfully',
                                                        })
                                                        .then(() => {});
                                                }
                                            });
                                        }
                                    }}
                                >
                                    Save Changes
                                </LoadingButton>
                            </Grid>
                            <Grid item xs="auto">
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    onClick={() => {
                                        if (formData) {
                                            setFormData({} as OnlyFansSubscriberHostessAutoReplyExpired);
                                        }
                                    }}
                                >
                                    Reset
                                </Button>
                            </Grid>
                        </Grid>
                    </StyledCard>
                </>
            )}
        </PageContainer>
    );
};

export default HostessAutoReplyToExpired;

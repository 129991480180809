import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LoadingButton from '@mui/lab/LoadingButton';
import { Button, CircularProgress, Collapse, Grid, IconButton, Stack, TableCell, TableRow, Typography } from '@mui/material';
import { Theme } from '@mui/system';
import dinero from 'dinero.js';
import moment from 'moment';
import { useDialog } from 'muibox';
import { useContext, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import { SettingsContext } from '../../../../store/SettingsContext';
import { d2f, stripHtml, targetUsersName } from '../../../../utils/common';
import MessageUsersCurrentSessionExclude from '../sendMessage/MessageUsersCurrentSessionExclude';

const MessageUsersHistorySessionsListRow = (props: {
    subscriber: any;
    row: any;
    currentUnsendMessageSessions: any;
    isLoadingCurrentUnsendMessageSessions: boolean;
    timezone: string;
    sextforceActive: boolean;
    hoursSinceMessage: number;
    showSalesMode: string;
    sessionsSalesData: any;
    sessionsSalesDataLoading: boolean;
    handleUnsendMessage: (sessionId: string) => void;
    // handleAddSalesSessionData: (sessionId: string, amount: number, net: number) => void;
    smallScreen: boolean;
    theme: Theme;
}) => {
    const {
        subscriber,
        row,
        currentUnsendMessageSessions,
        isLoadingCurrentUnsendMessageSessions,
        timezone,
        sextforceActive,
        hoursSinceMessage,
        showSalesMode,
        sessionsSalesData,
        sessionsSalesDataLoading,
        handleUnsendMessage,
        smallScreen,
        theme,
    } = props;

    const settingsContext = useContext(SettingsContext);
    const dialog = useDialog();
    const [open, setOpen] = useState(false);
    const [salesAmount, setSalesAmount] = useState<number>(0);
    const [salesNet, setSalesNet] = useState<number>(0);

    const currentUnsendMessageSession: any = currentUnsendMessageSessions.sessions.find(
        (unsendSession: any) => unsendSession.relatedSessionId === row._id,
    );

    useEffect(() => {
        setSalesAmount(sessionsSalesData && sessionsSalesData.totalAmount ? Math.trunc(d2f(sessionsSalesData.totalAmount) * 100) : 0);

        setSalesNet(sessionsSalesData && sessionsSalesData.totalNet ? Math.trunc(d2f(sessionsSalesData.totalNet) * 100) : 0);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [row._id, sessionsSalesData]);

    const roi = () => {
        const revenue: number = salesNet / 100;
        const cogs: number = d2f(row.payload.unitCost) * (row.messagesSent || 0) * settingsContext.financial.gbpToUsdRatio;

        return (
            <>
                {sextforceActive && row.payload && row.messagesSent ? (
                    sessionsSalesDataLoading ? (
                        <CircularProgress size={'14px'} />
                    ) : (
                        <span
                            style={{
                                fontFamily: 'monospace',
                                fontWeight: 'bold',
                                color: salesAmount > 0 ? theme.palette.success.dark : '',
                            }}
                        >
                            {(((revenue - cogs) / cogs) * 100).toLocaleString(undefined, {
                                minimumFractionDigits: 2,
                                maximumFractionDigits: 2,
                            })}
                            %
                        </span>
                    )
                ) : (
                    'N/A'
                )}
            </>
        );
    };

    const dineroNet = () => {
        return (
            <span style={{ fontFamily: 'monospace', color: salesAmount > 0 ? theme.palette.success.dark : '' }}>
                {dinero({
                    amount: showSalesMode === 'net' ? salesNet : salesAmount,
                    currency: 'USD',
                }).toFormat()}
            </span>
        );
    };

    return (
        <>
            <TableRow
                key={row._id}
                sx={{ '& > *': { borderBottom: 'unset' }, cursor: 'pointer' }}
                selected={open}
                onClick={() => setOpen(!open)}
            >
                <TableCell padding="none" sx={{ borderBottom: 'unset' }}>
                    <IconButton size="small">{open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}</IconButton>
                </TableCell>
                <TableCell component="th" scope="row" sx={{ paddingLeft: theme.spacing(1), paddingRight: 0, borderBottom: 'unset' }}>
                    {moment(row.startedAt).tz(timezone).format('L LT')}
                </TableCell>
                <TableCell sx={{ paddingLeft: theme.spacing(1), paddingRight: 0, borderBottom: 'unset' }}>
                    {targetUsersName(row.targetUsers, {})}
                </TableCell>
                <TableCell align="right" sx={{ paddingLeft: theme.spacing(1), paddingRight: 0, borderBottom: 'unset' }}>
                    {row.messagesSent ? row.messagesSent.toLocaleString() : 0}
                </TableCell>
                <TableCell
                    align="right"
                    sx={{ paddingLeft: theme.spacing(1), paddingRight: smallScreen ? 0 : theme.spacing(1), borderBottom: 'unset' }}
                >
                    {sextforceActive ? sessionsSalesDataLoading ? <CircularProgress size={'14px'} /> : dineroNet() : 'N/A'}
                </TableCell>
                {smallScreen && (
                    <>
                        <TableCell
                            sx={{
                                textAlign: 'right',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: { xs: 'none', sm: 'table-cell' },
                                paddingLeft: theme.spacing(1),
                                paddingRight: 0,
                                borderBottom: 'unset',
                            }}
                        >
                            {roi()}
                        </TableCell>
                        <TableCell
                            sx={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                display: { xs: 'none', sm: 'table-cell' },
                                paddingLeft: theme.spacing(1),
                                paddingRight: theme.spacing(1),
                                borderBottom: 'unset',
                            }}
                        >
                            {row.status}
                        </TableCell>
                    </>
                )}
            </TableRow>
            <TableRow>
                <TableCell
                    style={{ paddingBottom: 0, paddingTop: 0, backgroundColor: open ? theme.palette.grey[100] : '' }}
                    colSpan={smallScreen ? 7 : 5}
                >
                    <Collapse in={open} timeout="auto" unmountOnExit sx={{ maxWidth: { md: theme.breakpoints.values.sm } }}>
                        <Grid container spacing={1} sx={{ marginTop: theme.spacing(1), marginBottom: theme.spacing(2) }}>
                            <Grid item xs={12}>
                                <Typography variant="h6" gutterBottom component="div">
                                    Session Details
                                </Typography>
                            </Grid>
                            <Grid item xs={12}>
                                <strong>Message</strong>
                            </Grid>
                            <Grid item xs={12} sx={{ marginLeft: theme.spacing(2) }}>
                                {row.payload && 'message' in row.payload && row.payload.message ? stripHtml(row.payload.message) : ''}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>%USERNAME% Replacement</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload.usernameReplacement || <div style={{ color: theme.palette.grey[500] }}>None</div>}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>To</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {targetUsersName(row.targetUsers, {})}
                            </Grid>
                            {row.targetUsers === 'recentSpenders' && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>From</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {moment(row.payload.targetRecentSpendersDateFrom).tz(timezone).format('L')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <strong>To</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {moment(row.payload.targetRecentSpendersDateTo).tz(timezone).format('L')}
                                    </Grid>
                                </>
                            )}
                            {row.targetUsers === 'recentFans' && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>From</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {moment(row.payload.targetRecentFansDateFrom).tz(timezone).format('L')}
                                    </Grid>
                                    <Grid item xs={6}>
                                        <strong>To</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {moment(row.payload.targetRecentFansDateTo).tz(timezone).format('L')}
                                    </Grid>
                                </>
                            )}
                            {row.targetUsers === 'lists' && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>List Name</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {row.payload && row.payload.listName}
                                    </Grid>
                                </>
                            )}
                            {row.payload && row.payload.exclude && (
                                <>
                                    <Grid item xs={12}>
                                        <strong>Excludes</strong>
                                    </Grid>
                                    <Grid item xs={12} sx={{ marginLeft: theme.spacing(2), marginRight: theme.spacing(2) }}>
                                        <MessageUsersCurrentSessionExclude exclude={row.payload.exclude} theme={theme} />
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <strong>Attachment</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload && row.payload.mediaFiles && row.payload.mediaFiles.length > 0
                                    ? `${row.payload.mediaFiles.length.toLocaleString()} photo/video`
                                    : 'None'}
                            </Grid>
                            {row.payload.taggedCreators && row.payload.taggedCreators.length > 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>Tagged Creators</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column">
                                            {row.payload.taggedCreators.map(
                                                (taggedCreator: { id: number; name: string }, index: number) => {
                                                    return <div key={index}>{taggedCreator.name}</div>;
                                                },
                                            )}
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                            {row.payload.taggedReleaseForms && row.payload.taggedReleaseForms.length > 0 && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>Tagged Release Forms</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        <Stack direction="column">
                                            {row.payload.taggedReleaseForms.map(
                                                (taggedReleaseForm: { id: number; name: string }, index: number) => {
                                                    return <div key={index}>{taggedReleaseForm.name}</div>;
                                                },
                                            )}
                                        </Stack>
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={6}>
                                <strong>Auto Unsend</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload.autoUnsendTimerPeriod && row.payload.autoUnsendTimerPeriodAmount ? (
                                    `${row.payload.autoUnsendTimerPeriodAmount} ${row.payload.autoUnsendTimerPeriod} after session finished`
                                ) : (
                                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Delay Between Messages</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.payload.delayBetweenMessages ? (
                                    `${row.payload.delayBetweenMessages / 1000} seconds`
                                ) : (
                                    <div style={{ color: theme.palette.grey[500] }}>None</div>
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Started At</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {moment(row.startedAt).tz(timezone).format('L LT')}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Finished At</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {moment(row.lastStatusUpdate).tz(timezone).format('L LT')}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Messages Sent</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {(row.messagesSent || 0).toLocaleString()}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Cost</strong>
                            </Grid>
                            <Grid item xs={6}>
                                <span
                                    style={{
                                        fontFamily: 'monospace',
                                    }}
                                >
                                    {row.payload &&
                                        `${dinero({
                                            amount: Math.trunc(d2f(row.payload.unitCost) * (row.messagesSent || 0) * 100),
                                            currency: 'GBP',
                                        }).toFormat()} (about ${dinero({
                                            amount: Math.trunc(
                                                d2f(row.payload.unitCost) *
                                                    (row.messagesSent || 0) *
                                                    settingsContext.financial.gbpToUsdRatio *
                                                    100,
                                            ),
                                            currency: 'USD',
                                        }).toFormat()})`}
                                </span>
                            </Grid>
                            <Grid item xs={6}>
                                <strong>
                                    Sales within {hoursSinceMessage} Hours ({showSalesMode === 'net' ? 'Net' : 'Gross'})
                                </strong>
                            </Grid>
                            <Grid item xs={6}>
                                {sextforceActive ? (
                                    sessionsSalesDataLoading ? (
                                        <CircularProgress size={'14px'} />
                                    ) : (
                                        <span
                                            style={{
                                                fontFamily: 'monospace',
                                                fontWeight: 'bold',
                                                color: salesAmount > 0 ? theme.palette.success.dark : '',
                                            }}
                                        >
                                            {dinero({
                                                amount: showSalesMode === 'net' ? salesNet : salesAmount,
                                                currency: 'USD',
                                            }).toFormat()}
                                        </span>
                                    )
                                ) : (
                                    'N/A'
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Return on Investment (ROI) from Net</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {roi()}
                            </Grid>
                            <Grid item xs={6}>
                                <strong>Messages Available to Unsend</strong>
                            </Grid>
                            <Grid item xs={6}>
                                {row.availableToUnsend.toLocaleString()}
                            </Grid>
                            {currentUnsendMessageSessions && (currentUnsendMessageSessions.queue || currentUnsendMessageSession) && (
                                <>
                                    <Grid item xs={6}>
                                        <strong>Unsending Messages...</strong>
                                    </Grid>
                                    <Grid item xs={6}>
                                        {currentUnsendMessageSession &&
                                            currentUnsendMessageSession.followBackSessionSummary &&
                                            currentUnsendMessageSession.followBackSessionSummary.totalMessagesUnsent.toLocaleString()}
                                    </Grid>
                                </>
                            )}
                            <Grid item xs={12} md={6}>
                                <Button
                                    variant="contained"
                                    color="primary"
                                    size="medium"
                                    fullWidth
                                    component={Link}
                                    to={`/subscribers/${subscriber._id}/${settingsContext.services.messageUsers.homeUrl}/sendMessage?sessionId=${row._id}`}
                                >
                                    Repeat This Message
                                </Button>
                            </Grid>
                            <Grid item xs={12} md={6}>
                                <LoadingButton
                                    variant="contained"
                                    color="warning"
                                    size="medium"
                                    fullWidth
                                    loading={
                                        isLoadingCurrentUnsendMessageSessions ||
                                        currentUnsendMessageSessions.queue ||
                                        currentUnsendMessageSession
                                    }
                                    disabled={
                                        isLoadingCurrentUnsendMessageSessions ||
                                        currentUnsendMessageSessions.queue ||
                                        currentUnsendMessageSessions.sessions.length > 0 ||
                                        row.availableToUnsend === 0
                                    }
                                    onClick={() => {
                                        dialog
                                            .confirm({
                                                message:
                                                    'Are you sure you wish to unsend all these messages? This operation cannot be undone.',
                                                title: 'Unsend Messages',
                                                ok: { text: 'Unsend', variant: 'contained', color: 'warning' },
                                                cancel: { text: 'Cancel' },
                                            })
                                            .then(() => {
                                                handleUnsendMessage(row._id);
                                            })
                                            .catch(() => {});
                                    }}
                                >
                                    {row.availableToUnsend === 0
                                        ? 'No Messages to Unsend'
                                        : `Unsend ${row.availableToUnsend.toLocaleString()} Messages`}
                                </LoadingButton>
                            </Grid>
                        </Grid>
                    </Collapse>
                </TableCell>
            </TableRow>
        </>
    );
};

export default MessageUsersHistorySessionsListRow;

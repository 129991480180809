import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

export namespace OnlyFansMessaMessagesQueueDetails {
    export interface Response {
        responseType: string;
        id: number;
        text: string;
        rawText: string;
        giphyId: null;
        lockedText: boolean;
        isNew: boolean;
        isFree: boolean;
        isOpened: boolean;
        price: number;
        isMediaReady: boolean;
        mediaCount: number;
        media: Media[];
        previews: number[];
        createdAt: Date;
        changedAt: Date;
        scheduledAt?: Date | null;
        isTip: boolean;
        cancelSeconds: number;
        isLiked: boolean;
        canPurchase: boolean;
        isMarkdownDisabled: boolean;
        releaseForms: ReleaseForm[];
        userLists: string[];
        excludedLists: string[];
        fromUser: FromUser;
        isFromQueue: boolean;
        queueId: number;
        canUnsendQueue: boolean;
        unsendSecondsQueue: number;
    }

    export interface ReleaseForm {
        id: number;
        name: string;
        partnerSource: PartnerSource;
        type: ReleaseFormType;
        user: User;
    }

    export interface User {
        view: View;
        id: number;
        name: string;
        username: string;
        isVerified: boolean;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        ivStatus: IvStatus;
        isFromGuest: boolean;
    }

    export interface IvStatus {
        approved: boolean;
    }

    export enum ReleaseFormType {
        Document = 'document',
        User = 'user',
    }

    export enum PartnerSource {
        Document = 'document',
        Tag = 'tag',
    }

    export interface FromUser {
        view: string;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        header: string;
        headerSize: HeaderSize;
        headerThumbs: HeaderThumbs;
        id: number;
        name: string;
        username: string;
        canLookStory: boolean;
        canCommentStory: boolean;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        canPayInternal: boolean;
        hasScheduledStream: boolean;
        hasStream: boolean;
        hasStories: boolean;
        tipsEnabled: boolean;
        tipsTextEnabled: boolean;
        tipsMin: number;
        tipsMinInternal: number;
        tipsMax: number;
        canEarn: boolean;
        canAddSubscriber: boolean;
        subscribePrice: number;
        hasStripe: boolean;
        isStripeExist: boolean;
        subscriptionBundles: SubscriptionBundle[];
        canSendChatToAll: boolean;
        creditsMin: number;
        creditsMax: number;
        isPaywallPassed: boolean;
        creditsMinAlternatives: number;
        canCreateLists: boolean;
        lastSeen: Date;
        canReport: boolean;
        subscribedOn: null;
    }

    export interface SubscriptionBundle {
        id: number;
        discount: number;
        duration: number;
        price: number;
        canBuy: boolean;
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface HeaderSize {
        width: number;
        height: number;
    }

    export interface HeaderThumbs {
        w480: string;
        w760: string;
    }

    export interface Media {
        id: number;
        type: string;
        convertedToVideo: boolean;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        isReady: boolean;
        files: Files;
        duration: number;
        releaseForms: ReleaseForm[];
        hasCustomPreview: boolean;
        videoSources: { [key: string]: null };
    }

    export interface Files {
        full: Full;
        thumb: Full;
        preview: Full;
        squarePreview: Full;
        drm?: DRM;
    }

    export interface DRM {
        manifest: Manifest;
        signature: Signature;
    }

    export interface Manifest {
        hls: string;
        dash: string;
    }

    export interface Signature {
        hls: Dash;
        dash: Dash;
    }

    export interface Dash {
        'CloudFront-Policy': string;
        'CloudFront-Signature': string;
        'CloudFront-Key-Pair-Id': string;
    }

    export interface Full {
        url: null | string;
        width: number;
        height: number;
        size: number;
        sources?: Source[];
        options?: Option[];
    }

    export interface Option {
        id: number;
        url: string;
        selected: boolean;
        custom: boolean;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }

    export enum View {
        N = 'n',
        M = 'm',
        Cl = 'cl',
        X = 'x',
        S = 's',
    }
}

const useSubscriberMassDmQueueDetails = (enabled: boolean, queueId: number) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const queryClient = useQueryClient();
    const axios = useAxios();

    // Fetch Subscriber's Mass DM Queue
    const fetchMassDmQueue = async (): Promise<OnlyFansMessaMessagesQueueDetails.Response | null> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/massDM/queue/${queueId}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(error => {
                console.error(error);

                return null;
            });
    };

    const subscriberLists = useQuery(['subscriberMassDmQueueDetails', subscriber?._id, queueId], () => fetchMassDmQueue(), {
        refetchOnWindowFocus: false,
        staleTime: Infinity,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && enabled && queueId ? true : false,
    });

    return { ...subscriberLists };
};

export default useSubscriberMassDmQueueDetails;

import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

export namespace OnlyFansWSChatQueueUpdateMessage {
    export interface Message {
        chat_queue_update?: ChatQueueUpdate;
        chat_queue_finish?: ChatQueueUpdate;
    }

    export interface ChatQueueUpdate {
        id: number;
        date: Date;
        isReady: boolean;
        isDone: boolean;
        total: number;
        pending: number;
        canUnsend: boolean;
        unsendSeconds: number;
        hasError: boolean;
        isCanceled: boolean;
        isCouplePeopleMedia: boolean;
    }
}

const useSubscriberMassDmQueue = (enabled: boolean) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const queryClient = useQueryClient();
    const axios = useAxios();

    // Fetch Subscriber's Mass DM Queue
    const fetchMassDmQueue = async (): Promise<OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate[]> => {
        const query: string = `${settingsContext.routes.onlyFans.base}${subscriber._id}/massDM/queue`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(error => {
                console.error(error);

                return [];
            });
    };

    const subscriberLists = useQuery(['subscriberMassDmQueue', subscriber?._id], () => fetchMassDmQueue(), {
        refetchOnWindowFocus: false,
        // 3 minutes
        staleTime: 1000 * 60 * 3,
        // autoRefresh,
        refetchInterval: 1000 * 60 * 3,
        enabled: userContext.jwtToken && settingsContext.apiKey && subscriber && enabled ? true : false,
    });

    return { ...subscriberLists };
};

export default useSubscriberMassDmQueue;

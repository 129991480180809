import {
    Button,
    Card,
    CardContent,
    Grid,
    Skeleton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@mui/material';
import moment from 'moment';
import { useEffect, useState } from 'react';
import useMessageUsersScheduledSessions, { ScheduledTask } from '../../../../hooks/useMessageUsersScheduledSessions';
import MessageUsersScheduledListRow from './MessageUsersScheduledListRow';

const MessageUsersScheduledList = () => {
    const theme: Theme = useTheme();
    const smallScreen = useMediaQuery(theme.breakpoints.up('sm'));
    const timezone: string = moment.tz.guess();

    const { data: scheduledMessages, isLoading: scheduledMessagesLoading } = useMessageUsersScheduledSessions();

    const [showList, setShowList] = useState<boolean>(false);
    const [list, setList] = useState<ScheduledTask[]>([]);

    useEffect(() => {
        if (scheduledMessagesLoading || list.length > 0) {
            return;
        }

        if (scheduledMessages && scheduledMessages.length > 5) {
            setShowList(false);
        } else {
            setShowList(true);
        }
    }, [list.length, scheduledMessages, scheduledMessagesLoading]);

    useEffect(() => {
        if (scheduledMessagesLoading) {
            return;
        }

        if (scheduledMessages && scheduledMessages.length > 5) {
            setList(showList ? scheduledMessages : scheduledMessages.slice(0, 5));
        } else {
            setList(scheduledMessages || []);
        }
    }, [scheduledMessages, scheduledMessagesLoading, showList]);

    return (
        <>
            <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
                <Typography variant="h5" sx={{ marginTop: 2, marginBottom: 2 }}>
                    Scheduled Messages ({scheduledMessages ? scheduledMessages.length.toLocaleString() : 0})
                </Typography>
            </Stack>
            <Card variant="elevation" sx={{ marginBottom: 4 }}>
                <CardContent>
                    <Grid container flexGrow={1}>
                        {scheduledMessagesLoading ? (
                            <Skeleton />
                        ) : scheduledMessages && scheduledMessages.length === 0 ? (
                            <Typography variant="body1">No messages scheduled</Typography>
                        ) : (
                            <>
                                <Typography variant="body1" sx={{ marginBottom: 2 }}>
                                    This list shows the currently scheduled messages. Click the row for details and to cancel scheduled
                                    message.
                                </Typography>

                                <Table
                                    size="small"
                                    sx={{
                                        // no left/right padding on the first and last cells
                                        '& td:first-of-type, & th:first-of-type': {
                                            paddingLeft: 0,
                                        },
                                        // no right padding on the last cell
                                        '& td:last-of-type, & th:last-of-type': {
                                            paddingRight: 0,
                                        },
                                        // Bold header
                                        '& th': {
                                            fontWeight: 'bold',
                                        },
                                        '& th, & td': {
                                            paddingLeft: 0,
                                            paddingRight: 1,

                                            // make last column sticky
                                            '&:last-child': {
                                                position: 'sticky',
                                                right: 0,
                                                backgroundColor: theme.palette.background.paper,
                                                // borderBottom: `1px solid ${theme.palette.divider}`,
                                            },
                                        },
                                    }}
                                >
                                    <TableHead>
                                        <TableRow>
                                            <TableCell width={20} />
                                            <TableCell sx={{ width: { xs: '100px', sm: '130px' } }}>Date</TableCell>
                                            <TableCell width={180} sx={{ fontWeight: 'bold' }}>
                                                Target
                                            </TableCell>
                                            <TableCell sx={{ display: { xs: 'none', sm: 'block' } }}>Message</TableCell>
                                            <TableCell align="center" width="63px">
                                                Repeat
                                            </TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {list.map(scheduledMessage => (
                                            <MessageUsersScheduledListRow
                                                row={scheduledMessage}
                                                timezone={timezone}
                                                smallScreen={smallScreen}
                                                key={scheduledMessage._id}
                                            />
                                        ))}
                                    </TableBody>
                                </Table>
                                {scheduledMessages && scheduledMessages.length > 5 && (
                                    <Button variant="text" onClick={() => setShowList(!showList)} sx={{ marginTop: 2 }}>
                                        {showList ? 'Show Less' : 'Show All'}
                                    </Button>
                                )}
                            </>
                        )}
                    </Grid>
                </CardContent>
            </Card>
        </>
    );
};

export default MessageUsersScheduledList;

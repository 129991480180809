import CancelIcon from '@mui/icons-material/Cancel';
import { IconButton, TableCell, TableRow, Theme, Tooltip, useTheme } from '@mui/material';
import moment from 'moment';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { OnlyFansWSChatQueueUpdateMessage } from '../../../../hooks/useSubscriberMassDmQueue';
import useSubscriberMassDmQueueDetails from '../../../../hooks/useSubscriberMassDmQueueDetails';

interface Props {
    queueItem: OnlyFansWSChatQueueUpdateMessage.ChatQueueUpdate;
    handleCancel: (id: number) => void;
}

const MessageUsersAutoMassDmQueueListRow = (props: Props) => {
    const { queueItem, handleCancel } = props;
    const theme: Theme = useTheme();

    const { data: queueDetails, isLoading: isQueueDetailsLoading } = useSubscriberMassDmQueueDetails(true, queueItem.id);

    const calculatePercentage = (pending: number, total: number): number => {
        return ((total - pending) / total) * 100;
    };

    return (
        <TableRow>
            <TableCell
                align="center"
                sx={{
                    fontFamily: 'monospace',
                }}
            >
                <Tooltip
                    title={
                        <span>
                            <strong>
                                {calculatePercentage(queueItem.pending, queueItem.total).toLocaleString(undefined, {
                                    maximumFractionDigits: 2,
                                    maximumSignificantDigits: 2,
                                })}
                                % Complete
                            </strong>
                            <br />
                            ID: {queueItem.id.toString()}
                        </span>
                    }
                >
                    <span>
                        <CircularProgressbar
                            value={calculatePercentage(queueItem.pending, queueItem.total)}
                            strokeWidth={50}
                            styles={buildStyles({
                                pathColor: theme.palette.primary.main,
                                backgroundColor: theme.palette.grey.A100,
                                strokeLinecap: 'butt',
                            })}
                        />
                    </span>
                </Tooltip>
            </TableCell>
            <TableCell
                sx={{
                    fontFamily: 'monospace',
                }}
            >
                {moment(queueItem.date).format('L LT')}
            </TableCell>
            <TableCell>Blah</TableCell>
            <TableCell
                align="right"
                sx={{
                    fontFamily: 'monospace',
                }}
            >
                {queueItem.pending.toLocaleString()}
            </TableCell>
            <TableCell
                align="right"
                sx={{
                    fontFamily: 'monospace',
                }}
            >
                {queueItem.total.toLocaleString()}
            </TableCell>
            <TableCell align="center">
                <IconButton
                    color="error"
                    onClick={() => {
                        handleCancel(queueItem.id);
                    }}
                >
                    <CancelIcon />
                </IconButton>
            </TableCell>
        </TableRow>
    );
};

export default MessageUsersAutoMassDmQueueListRow;

import ClearIcon from '@mui/icons-material/Clear';
import { Box, FormControl, Grid, InputLabel, MenuItem, Select, TextField, Theme, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterMoment } from '@mui/x-date-pickers/AdapterMoment';
import { useState } from 'react';
import { SextforceAutoMassDmMessageListSortBy, SextforceAutoMassDmMessageStatus } from '../../../../hooks/useMessageUsersMassDm';
import StyledCard from '../../../common/StyledCard';

type Props = {
    massDmsLoading: boolean;
    status: SextforceAutoMassDmMessageStatus;
    setStatus: (active: SextforceAutoMassDmMessageStatus) => void;
    setQuery: (query: string) => void;
    sortBy: SextforceAutoMassDmMessageListSortBy;
    setSortBy: (sortBy: SextforceAutoMassDmMessageListSortBy) => void;
    theme: Theme;
};

const MessageUsersAutoMassDmSearchBar = (props: Props) => {
    const { massDmsLoading, status, setStatus, setQuery, sortBy, setSortBy, theme } = props;

    const [queryText, setQueryText] = useState<string>('');

    const handleQueryChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        // Add a small delay to avoid too many requests
        setTimeout(() => {
            setQuery(event.target.value);
        }, 500);

        setQueryText(event.target.value);
    };

    return (
        <StyledCard noCard noBottomMargin>
            <Box
                sx={{
                    bgcolor: 'grey.50',
                    p: 2,
                    pt: 4,
                    // Elevation 1
                    boxShadow: theme.shadows[1],
                    borderRadius: theme.shape.borderRadius,
                }}
            >
                <LocalizationProvider dateAdapter={AdapterMoment}>
                    <Grid container spacing={1} alignItems="center" sx={{ marginBottom: theme.spacing(2) }}>
                        <Grid item xs={12}>
                            <Grid container spacing={1} alignItems="center">
                                <Grid item xs="auto">
                                    <ToggleButtonGroup
                                        color="primary"
                                        value={status}
                                        exclusive
                                        size="small"
                                        onChange={(event, value) => {
                                            if (value !== null) {
                                                setStatus(value);
                                            }
                                        }}
                                        sx={{
                                            mt: '4px',
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    >
                                        <ToggleButton value={'sent'}>Sent</ToggleButton>
                                        <ToggleButton value={'scheduled'}>Scheduled</ToggleButton>
                                        <ToggleButton value={'unsent'}>Unsent</ToggleButton>
                                    </ToggleButtonGroup>
                                </Grid>

                                <Grid item xs>
                                    <TextField
                                        fullWidth
                                        label="Search"
                                        value={queryText}
                                        onChange={handleQueryChange}
                                        onKeyDown={e => {
                                            // Clear on Escape
                                            if (e.key === 'Escape') {
                                                setQuery('');
                                                setQueryText('');
                                            }
                                        }}
                                        InputProps={{
                                            endAdornment: queryText.length > 0 && (
                                                <ClearIcon
                                                    onClick={() => {
                                                        setQuery('');
                                                        setQueryText('');
                                                    }}
                                                    sx={{
                                                        cursor: 'pointer',
                                                    }}
                                                />
                                            ),
                                        }}
                                        sx={{
                                            backgroundColor: theme.palette.background.paper,
                                        }}
                                    />
                                </Grid>
                            </Grid>
                        </Grid>

                        <Grid item xs sm>
                            <FormControl fullWidth size="small">
                                <InputLabel id="sort-by-label">Sort By</InputLabel>
                                <Select
                                    labelId="sort-by-label"
                                    label="Sort By"
                                    fullWidth
                                    size="small"
                                    value={sortBy}
                                    onChange={e => {
                                        setSortBy(e.target.value as SextforceAutoMassDmMessageListSortBy);
                                    }}
                                    sx={{
                                        backgroundColor: theme.palette.background.paper,
                                    }}
                                >
                                    <MenuItem value={'date'}>Date Added</MenuItem>
                                    <MenuItem value={'name'}>Name</MenuItem>
                                    <MenuItem value={'sentCount'}>Sent Count</MenuItem>
                                    <MenuItem value={'viewedCount'}>Views Count</MenuItem>
                                    <MenuItem value={'purchasedCount'}>Purchases Count</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>
                </LocalizationProvider>
            </Box>
        </StyledCard>
    );
};

export default MessageUsersAutoMassDmSearchBar;

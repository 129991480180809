import { useSnackbar } from 'notistack';
import { useContext } from 'react';
import { useQuery, useQueryClient } from 'react-query';
import { SettingsContext } from '../store/SettingsContext';
import { UserContext } from '../store/UserContext';
import useAxios from './useAxios';
import useSubscriber from './useSubscriber';

export const arraySextforceAutoMassDmMessageStatus = ['sent', 'scheduled', 'unsent'] as const;
export type SextforceAutoMassDmMessageStatus = typeof arraySextforceAutoMassDmMessageStatus[number];

export interface SextforceAutoMassDmMessage {
    _id?: string;
    subscriberId: string;
    massDmId: number;
    name?: string;
    colorId?: string;
    date: Date;
    scheduledAt?: Date;
    status: SextforceAutoMassDmMessageStatus;
    sentCount: number;
    viewedCount: number;
    canUnsend: boolean;
    purchasedCount: number;
    isCancelled: boolean;
    isFree: boolean;
    canSendMessageToBuyers: boolean;
    price: number;
    text: string;
    rawText: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    messageDetails?: any;
    createdAt?: Date;
    updatedAt?: Date;
}

export const arraySextforceAutoMassDmMessageListSortBy = ['date', 'name', 'sentCount', 'viewedCount', 'purchasedCount'];
export type SextforceAutoMassDmMessageListSortBy = typeof arraySextforceAutoMassDmMessageListSortBy[number];

export namespace OnlyFansMessaMessagesQueueDetails {
    export interface Response {
        responseType: string;
        id: number;
        text: string;
        rawText: string;
        giphyId: null;
        lockedText: boolean;
        isNew: boolean;
        isFree: boolean;
        isOpened: boolean;
        price: number;
        isMediaReady: boolean;
        mediaCount: number;
        media: Media[];
        previews: number[];
        createdAt: Date;
        changedAt: Date;
        scheduledAt?: Date | null;
        isTip: boolean;
        cancelSeconds: number;
        isLiked: boolean;
        canPurchase: boolean;
        isMarkdownDisabled: boolean;
        releaseForms: ReleaseForm[];
        userLists: string[];
        excludedLists: string[];
        fromUser: FromUser;
        isFromQueue: boolean;
        queueId: number;
        canUnsendQueue: boolean;
        unsendSecondsQueue: number;
    }

    export enum View {
        N = 'n',
        M = 'm',
        Cl = 'cl',
        X = 'x',
        S = 's',
    }

    export interface ReleaseForm {
        id: number;
        name: string;
        partnerSource: PartnerSource;
        type: ReleaseFormType;
        user: User;
    }

    export interface User {
        view: View;
        id: number;
        name: string;
        username: string;
        isVerified: boolean;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        ivStatus: IvStatus;
        isFromGuest: boolean;
    }

    export interface IvStatus {
        approved: boolean;
    }

    export enum ReleaseFormType {
        Document = 'document',
        User = 'user',
    }

    export enum PartnerSource {
        Document = 'document',
        Tag = 'tag',
    }

    export interface FromUser {
        view: string;
        avatar: string;
        avatarThumbs: AvatarThumbs;
        header: string;
        headerSize: HeaderSize;
        headerThumbs: HeaderThumbs;
        id: number;
        name: string;
        username: string;
        canLookStory: boolean;
        canCommentStory: boolean;
        hasNotViewedStory: boolean;
        isVerified: boolean;
        canPayInternal: boolean;
        hasScheduledStream: boolean;
        hasStream: boolean;
        hasStories: boolean;
        tipsEnabled: boolean;
        tipsTextEnabled: boolean;
        tipsMin: number;
        tipsMinInternal: number;
        tipsMax: number;
        canEarn: boolean;
        canAddSubscriber: boolean;
        subscribePrice: number;
        hasStripe: boolean;
        isStripeExist: boolean;
        subscriptionBundles: SubscriptionBundle[];
        canSendChatToAll: boolean;
        creditsMin: number;
        creditsMax: number;
        isPaywallPassed: boolean;
        creditsMinAlternatives: number;
        canCreateLists: boolean;
        lastSeen: Date;
        canReport: boolean;
        subscribedOn: null;
    }

    export interface SubscriptionBundle {
        id: number;
        discount: number;
        duration: number;
        price: number;
        canBuy: boolean;
    }

    export interface AvatarThumbs {
        c50: string;
        c144: string;
    }

    export interface HeaderSize {
        width: number;
        height: number;
    }

    export interface HeaderThumbs {
        w480: string;
        w760: string;
    }

    export interface Media {
        id: number;
        type: string;
        convertedToVideo: boolean;
        canView: boolean;
        hasError: boolean;
        createdAt: Date;
        isReady: boolean;
        files: Files;
        duration: number;
        releaseForms: ReleaseForm[];
        hasCustomPreview: boolean;
        videoSources: { [key: string]: null };
    }

    export interface Files {
        full: Full;
        thumb: Full;
        preview: Full;
        squarePreview: Full;
        drm?: DRM;
    }

    export interface DRM {
        manifest: Manifest;
        signature: Signature;
    }

    export interface Manifest {
        hls: string;
        dash: string;
    }

    export interface Signature {
        hls: Dash;
        dash: Dash;
    }

    export interface Dash {
        'CloudFront-Policy': string;
        'CloudFront-Signature': string;
        'CloudFront-Key-Pair-Id': string;
    }

    export interface Full {
        url: null | string;
        width: number;
        height: number;
        size: number;
        sources?: Source[];
        options?: Option[];
    }

    export interface Option {
        id: number;
        url: string;
        selected: boolean;
        custom: boolean;
    }

    export interface Source {
        source: string;
        width: number;
        height: number;
        size: number;
        duration: number;
    }
}

const useMessageUsersMassDm = (
    searchQuery: string,
    status: SextforceAutoMassDmMessageStatus,
    offset: number,
    limit: number,
    sortBy: SextforceAutoMassDmMessageListSortBy,
) => {
    const userContext = useContext(UserContext);
    const settingsContext = useContext(SettingsContext);
    const { data: subscriber } = useSubscriber();
    const queryClient = useQueryClient();
    const axios = useAxios();
    const { enqueueSnackbar } = useSnackbar();

    // Fetch Subscriber's Mass DMs
    const fetchMassDms = async (): Promise<{ list: SextforceAutoMassDmMessage[]; total: number; skip: number; limit: number }> => {
        const query: string = `${settingsContext.routes.massDm.base}${subscriber._id}/list?query=${searchQuery}&status=${status}&offset=${offset}&limit=${limit}&sortBy=${sortBy}`;

        return axios
            .get(query)
            .then(response => response.data)
            .catch(error => {
                console.error(error);

                enqueueSnackbar('Failed to fetch Mass DMs', { variant: 'error' });

                return { list: [], total: 0, skip: offset, limit };
            });
    };

    const subscriberLists = useQuery(
        ['subscriberMassDms', subscriber?._id, searchQuery, status, offset, limit, sortBy],
        () => fetchMassDms(),
        {
            refetchOnWindowFocus: false,
            // 5 minutes
            staleTime: 1000 * 60 * 5,
            keepPreviousData: true,
            enabled: userContext.jwtToken && settingsContext.apiKey && subscriber ? true : false,
        },
    );

    return { ...subscriberLists };
};

export default useMessageUsersMassDm;

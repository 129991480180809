import { Box, Card, CardContent, Grid, Slider, Typography } from '@mui/material';
import useTheme from '@mui/material/styles/useTheme';
import { Theme } from '@mui/system';
import { useContext, useState } from 'react';
import BackNavigationButton from '../../../components/common/BackNavigationButton';
import useSubscriber from '../../../hooks/useSubscriber';
import { SettingsContext } from '../../../store/SettingsContext';

import * as d3 from 'd3';
import moment from 'moment';
import { useEffect, useRef } from 'react';
import PageContainer from '../../../components/common/PageContainter';
import useSubscriberPerformerTop from '../../../hooks/useSubscriberPerformerTop';
import PerformerRankingMyRankingCard from './PerformerRankingMyRankingCard';

const data: PerformerRankingDataPoint[] = [
    {
        ranking: '0.01',
        min: 317946.91,
        avg: 339273.18,
        max: 360599.45,
    },
    {
        ranking: '0.02',
        min: 247030.01,
        avg: 267927.345,
        max: 288824.68,
    },
    {
        ranking: '0.03',
        min: 49874.64,
        avg: 143304.96,
        max: 236735.28,
    },
    {
        ranking: '0.04',
        min: 170547.5,
        avg: 182509.39,
        max: 194471.28,
    },
    {
        ranking: '0.05',
        min: 156230.17,
        avg: 161587.51,
        max: 166944.85,
    },
    {
        ranking: '0.06',
        min: 146513.52,
        avg: 150733.53,
        max: 154953.54,
    },
    {
        ranking: '0.07',
        min: 134963.13,
        avg: 139340.66,
        max: 143718.19,
    },
    {
        ranking: '0.08',
        min: 127050.23,
        avg: 130607.26000000001,
        max: 134164.29,
    },
    {
        ranking: '0.09',
        min: 117978.5,
        avg: 123842.18,
        max: 129705.86,
    },
    {
        ranking: '0.10',
        min: 109352.24,
        avg: 112063.235,
        max: 114774.23,
    },
    {
        ranking: '0.11',
        min: 103210.69,
        avg: 107221.675,
        max: 111232.66,
    },
    {
        ranking: '0.12',
        min: 102806.81,
        avg: 104693.37,
        max: 106579.93,
    },
    {
        ranking: '0.13',
        min: 98584.32,
        avg: 99981.58,
        max: 101378.84,
    },
    {
        ranking: '0.14',
        min: 92832.59,
        avg: 93481.215,
        max: 94129.84,
    },
    {
        ranking: '0.15',
        min: 10699.75,
        avg: 51959.145,
        max: 93218.54,
    },
    {
        ranking: '0.16',
        min: 84016.04,
        avg: 84880.275,
        max: 85744.51,
    },
    {
        ranking: '0.17',
        min: 75914.41,
        avg: 79168.09,
        max: 82421.77,
    },
    {
        ranking: '0.18',
        min: 75996.04,
        avg: 78619.82999999999,
        max: 81243.62,
    },
    {
        ranking: '0.19',
        min: 75435.74,
        avg: 79810.955,
        max: 84186.17,
    },
    {
        ranking: '0.20',
        min: 73054.93,
        avg: 75413.17499999999,
        max: 77771.42,
    },
    {
        ranking: '0.21',
        min: 51838.78,
        avg: 62336.905,
        max: 72835.03,
    },
    {
        ranking: '0.22',
        min: 10843.14,
        avg: 37902.555,
        max: 64961.97,
    },
    {
        ranking: '0.23',
        min: 65462.74,
        avg: 67539.805,
        max: 69616.87,
    },
    {
        ranking: '0.24',
        min: 65445.86,
        avg: 67528.01000000001,
        max: 69610.16,
    },
    {
        ranking: '0.25',
        min: 63065.54,
        avg: 64582.41,
        max: 66099.28,
    },
    {
        ranking: '0.26',
        min: 61129.7,
        avg: 62228.055,
        max: 63326.41,
    },
    {
        ranking: '0.27',
        min: 60195.57,
        avg: 61121.16,
        max: 62046.75,
    },
    {
        ranking: '0.28',
        min: 58333.25,
        avg: 61137.235,
        max: 63941.22,
    },
    {
        ranking: '0.29',
        min: 57824.92,
        avg: 59538.33,
        max: 61251.74,
    },
    {
        ranking: '0.30',
        min: 57045.71,
        avg: 58889.649999999994,
        max: 60733.59,
    },
    {
        ranking: '0.31',
        min: 53526.26,
        avg: 54984.485,
        max: 56442.71,
    },
    {
        ranking: '0.32',
        min: 50380.01,
        avg: 53746.61,
        max: 57113.21,
    },
    {
        ranking: '0.33',
        min: 52499.3,
        avg: 52499.3,
        max: 52499.3,
    },
    {
        ranking: '0.34',
        min: 49593.08,
        avg: 51881.64,
        max: 54170.2,
    },
    {
        ranking: '0.35',
        min: 50759.99,
        avg: 51809.020000000004,
        max: 52858.05,
    },
    {
        ranking: '0.36',
        min: 50155.57,
        avg: 51656.1,
        max: 53156.63,
    },
    {
        ranking: '0.37',
        min: 48673.16,
        avg: 49809.955,
        max: 50946.75,
    },
    {
        ranking: '0.38',
        min: 48277.15,
        avg: 48720.729999999996,
        max: 49164.31,
    },
    {
        ranking: '0.40',
        min: 45764.71,
        avg: 47909.08,
        max: 50053.45,
    },
    {
        ranking: '0.41',
        min: 45595.34,
        avg: 46043.94,
        max: 46492.54,
    },
    {
        ranking: '0.42',
        min: 34526.99,
        avg: 39775.33,
        max: 45023.67,
    },
    {
        ranking: '0.43',
        min: 41538.6,
        avg: 42949.66,
        max: 44360.72,
    },
    {
        ranking: '0.44',
        min: 42408.85,
        avg: 43767.92,
        max: 45126.99,
    },
    {
        ranking: '0.45',
        min: 40364.7,
        avg: 42135.285,
        max: 43905.87,
    },
    {
        ranking: '0.46',
        min: 41019.32,
        avg: 42266.585,
        max: 43513.85,
    },
    {
        ranking: '0.47',
        min: 40278.44,
        avg: 40978.37,
        max: 41678.3,
    },
    {
        ranking: '0.48',
        min: 38802.47,
        avg: 42820.09,
        max: 46837.71,
    },
    {
        ranking: '0.49',
        min: 39576.74,
        avg: 40585.155,
        max: 41593.57,
    },
    {
        ranking: '0.50',
        min: 37857.39,
        avg: 39125.665,
        max: 40393.94,
    },
    {
        ranking: '0.51',
        min: 39317.12,
        avg: 39317.12,
        max: 39317.12,
    },
    {
        ranking: '0.52',
        min: 37084.31,
        avg: 37883.255,
        max: 38682.2,
    },
    {
        ranking: '0.53',
        min: 37914.51,
        avg: 42476.385,
        max: 47038.26,
    },
    {
        ranking: '0.54',
        min: 37943.56,
        avg: 38046.345,
        max: 38149.13,
    },
    {
        ranking: '0.55',
        min: 30913.11,
        avg: 33933.69,
        max: 36954.27,
    },
    {
        ranking: '0.56',
        min: 31454.48,
        avg: 34335.03,
        max: 37215.58,
    },
    {
        ranking: '0.57',
        min: 34699.18,
        avg: 35149.145000000004,
        max: 35599.11,
    },
    {
        ranking: '0.58',
        min: 33749.97,
        avg: 34979.854999999996,
        max: 36209.74,
    },
    {
        ranking: '0.59',
        min: 31983.7,
        avg: 33531.965000000004,
        max: 35080.23,
    },
    {
        ranking: '0.60',
        min: 32793.08,
        avg: 33743.67,
        max: 34694.26,
    },
    {
        ranking: '0.61',
        min: 32786.42,
        avg: 33269.695,
        max: 33752.97,
    },
    {
        ranking: '0.62',
        min: 32391.44,
        avg: 32998.55,
        max: 33605.66,
    },
    {
        ranking: '0.63',
        min: 32370.15,
        avg: 32668.025,
        max: 32965.9,
    },
    {
        ranking: '0.64',
        min: 31118.45,
        avg: 32184.32,
        max: 33250.19,
    },
    {
        ranking: '0.65',
        min: 32533,
        avg: 33109.92,
        max: 33686.84,
    },
    {
        ranking: '0.66',
        min: 31086.99,
        avg: 31644.655,
        max: 32202.32,
    },
    {
        ranking: '0.67',
        min: 30615.98,
        avg: 30629.54,
        max: 30643.1,
    },
    {
        ranking: '0.68',
        min: 30890.62,
        avg: 31284.86,
        max: 31679.1,
    },
    {
        ranking: '0.69',
        min: 29422.56,
        avg: 30176.515,
        max: 30930.47,
    },
    {
        ranking: '0.71',
        min: 28333.53,
        avg: 29296.525,
        max: 30259.52,
    },
    {
        ranking: '0.72',
        min: 28204.65,
        avg: 28204.65,
        max: 28204.65,
    },
    {
        ranking: '0.73',
        min: 27714.94,
        avg: 28732.53,
        max: 29750.12,
    },
    {
        ranking: '0.74',
        min: 26401.93,
        avg: 28264.255,
        max: 30126.58,
    },
    {
        ranking: '0.76',
        min: 28056.75,
        avg: 28056.75,
        max: 28056.75,
    },
    {
        ranking: '0.77',
        min: 26515.73,
        avg: 26901.11,
        max: 27286.49,
    },
    {
        ranking: '0.78',
        min: 26767.21,
        avg: 27227.83,
        max: 27688.45,
    },
    {
        ranking: '0.79',
        min: 21333.51,
        avg: 24732.58,
        max: 28131.65,
    },
    {
        ranking: '0.80',
        min: 26282.36,
        avg: 26933.425000000003,
        max: 27584.49,
    },
    {
        ranking: '0.81',
        min: 26243.62,
        avg: 26710.33,
        max: 27177.04,
    },
    {
        ranking: '0.82',
        min: 18602.52,
        avg: 22255.29,
        max: 25908.06,
    },
    {
        ranking: '0.83',
        min: 25878.17,
        avg: 26254.254999999997,
        max: 26630.34,
    },
    {
        ranking: '0.84',
        min: 23647.04,
        avg: 24845.23,
        max: 26043.42,
    },
    {
        ranking: '0.85',
        min: 25728.64,
        avg: 25893.945,
        max: 26059.25,
    },
    {
        ranking: '0.86',
        min: 24363.21,
        avg: 24728.864999999998,
        max: 25094.52,
    },
    {
        ranking: '0.87',
        min: 25462.57,
        avg: 25462.57,
        max: 25462.57,
    },
    {
        ranking: '0.88',
        min: 24682.12,
        avg: 26474.44,
        max: 28266.76,
    },
    {
        ranking: '0.90',
        min: 23828.05,
        avg: 24291.36,
        max: 24754.67,
    },
    {
        ranking: '0.91',
        min: 23893.77,
        avg: 24427.015,
        max: 24960.26,
    },
    {
        ranking: '0.92',
        min: 23544.59,
        avg: 28837.975,
        max: 34131.36,
    },
    {
        ranking: '0.93',
        min: 22384.08,
        avg: 23253.21,
        max: 24122.34,
    },
    {
        ranking: '0.94',
        min: 23299.56,
        avg: 23727.725,
        max: 24155.89,
    },
    {
        ranking: '0.95',
        min: 23674.81,
        avg: 23688.745000000003,
        max: 23702.68,
    },
    {
        ranking: '0.96',
        min: 21037.89,
        avg: 22189.545,
        max: 23341.2,
    },
    {
        ranking: '0.97',
        min: 21899.66,
        avg: 24261.855,
        max: 26624.05,
    },
    {
        ranking: '0.98',
        min: 23169.45,
        avg: 23169.45,
        max: 23169.45,
    },
    {
        ranking: '0.99',
        min: 22008.92,
        avg: 22518.824999999997,
        max: 23028.73,
    },
    {
        ranking: '1.00',
        min: 16202.81,
        avg: 31109.765,
        max: 46016.72,
    },
    {
        ranking: '1.10',
        min: 19448.19,
        avg: 29271.315000000002,
        max: 39094.44,
    },
    {
        ranking: '1.20',
        min: 17943.01,
        avg: 23312.934999999998,
        max: 28682.86,
    },
    {
        ranking: '1.30',
        min: 8238.55,
        avg: 13815.494999999999,
        max: 19392.44,
    },
    {
        ranking: '1.40',
        min: 12635.97,
        avg: 15029.61,
        max: 17423.25,
    },
    {
        ranking: '1.50',
        min: 10994.6,
        avg: 14049.45,
        max: 17104.3,
    },
    {
        ranking: '1.60',
        min: 13596.29,
        avg: 15377.215,
        max: 17158.14,
    },
    {
        ranking: '1.70',
        min: 9289.54,
        avg: 13410.565,
        max: 17531.59,
    },
    {
        ranking: '1.80',
        min: 11764.03,
        avg: 13564.82,
        max: 15365.61,
    },
    {
        ranking: '1.90',
        min: 6679.18,
        avg: 11469.535,
        max: 16259.89,
    },
    {
        ranking: '2.00',
        min: 10523.95,
        avg: 11390.045,
        max: 12256.14,
    },
    {
        ranking: '2.10',
        min: 4588.59,
        avg: 7992.205,
        max: 11395.82,
    },
    {
        ranking: '2.20',
        min: 9876.44,
        avg: 10732.375,
        max: 11588.31,
    },
    {
        ranking: '2.30',
        min: 6726.7,
        avg: 9220.745,
        max: 11714.79,
    },
    {
        ranking: '2.40',
        min: 8588.33,
        avg: 9111.715,
        max: 9635.1,
    },
    {
        ranking: '2.50',
        min: 7804.69,
        avg: 12142.045,
        max: 16479.4,
    },
    {
        ranking: '2.60',
        min: 7531.04,
        avg: 9547.53,
        max: 11564.02,
    },
    {
        ranking: '2.70',
        min: 4735.38,
        avg: 8683.02,
        max: 12630.66,
    },
    {
        ranking: '2.80',
        min: 6009.65,
        avg: 7111.755,
        max: 8213.86,
    },
    {
        ranking: '2.90',
        min: 2586.25,
        avg: 5328.24,
        max: 8070.23,
    },
    {
        ranking: '3.00',
        min: 5794.88,
        avg: 7387.594999999999,
        max: 8980.31,
    },
    {
        ranking: '3.10',
        min: 5455.27,
        avg: 8642.470000000001,
        max: 11829.67,
    },
    {
        ranking: '3.20',
        min: 6485.37,
        avg: 6698.045,
        max: 6910.72,
    },
    {
        ranking: '3.30',
        min: 6238.01,
        avg: 6956.005,
        max: 7674,
    },
    {
        ranking: '3.40',
        min: 5748.66,
        avg: 6275.895,
        max: 6803.13,
    },
    {
        ranking: '3.50',
        min: 2990,
        avg: 4813.495,
        max: 6636.99,
    },
    {
        ranking: '3.60',
        min: 5323.1,
        avg: 6822.205,
        max: 8321.31,
    },
    {
        ranking: '3.70',
        min: 5030.92,
        avg: 5392.96,
        max: 5755,
    },
    {
        ranking: '3.80',
        min: 2260.55,
        avg: 3886.77,
        max: 5512.99,
    },
    {
        ranking: '3.90',
        min: 5037.31,
        avg: 5450.99,
        max: 5864.67,
    },
    {
        ranking: '4.00',
        min: 4979.88,
        avg: 5078.91,
        max: 5177.94,
    },
    {
        ranking: '4.10',
        min: 3230.23,
        avg: 4146.38,
        max: 5062.53,
    },
    {
        ranking: '4.20',
        min: 3888.7,
        avg: 5668.555,
        max: 7448.41,
    },
    {
        ranking: '4.30',
        min: 4355.34,
        avg: 4895.38,
        max: 5435.42,
    },
    {
        ranking: '4.40',
        min: 2216.58,
        avg: 5300.28,
        max: 8383.98,
    },
    {
        ranking: '4.50',
        min: 4025.31,
        avg: 4416.3,
        max: 4807.29,
    },
    {
        ranking: '4.60',
        min: 3516.89,
        avg: 4085.29,
        max: 4653.69,
    },
    {
        ranking: '4.70',
        min: 3123.57,
        avg: 3881.33,
        max: 4639.09,
    },
    {
        ranking: '4.80',
        min: 3549.61,
        avg: 3791.5299999999997,
        max: 4033.45,
    },
    {
        ranking: '4.90',
        min: 1802.49,
        avg: 2892.87,
        max: 3983.25,
    },
    {
        ranking: '5.00',
        min: 2911.29,
        avg: 3472.12,
        max: 4032.95,
    },
    {
        ranking: '5.10',
        min: 2573.63,
        avg: 3271.12,
        max: 3968.61,
    },
    {
        ranking: '5.20',
        min: 2554.74,
        avg: 3045.16,
        max: 3535.58,
    },
    {
        ranking: '5.30',
        min: 3213.18,
        avg: 4894.815,
        max: 6576.45,
    },
    {
        ranking: '5.40',
        min: 3480.26,
        avg: 4536.04,
        max: 5591.82,
    },
    {
        ranking: '5.50',
        min: 3260.03,
        avg: 5050.72,
        max: 6841.41,
    },
    {
        ranking: '5.60',
        min: 2971.9,
        avg: 3149.05,
        max: 3326.2,
    },
    {
        ranking: '5.70',
        min: 2096.79,
        avg: 2704.415,
        max: 3312.04,
    },
    {
        ranking: '5.80',
        min: 2691.66,
        avg: 3019.225,
        max: 3346.79,
    },
    {
        ranking: '5.90',
        min: 2852.55,
        avg: 3293.5150000000003,
        max: 3734.48,
    },
    {
        ranking: '6.00',
        min: 1272.96,
        avg: 2173.99,
        max: 3075.02,
    },
    {
        ranking: '6.10',
        min: 2959.82,
        avg: 3539.125,
        max: 4118.43,
    },
    {
        ranking: '6.20',
        min: 762.13,
        avg: 2007.895,
        max: 3253.66,
    },
    {
        ranking: '6.30',
        min: 2490.54,
        avg: 2586.2200000000003,
        max: 2681.9,
    },
    {
        ranking: '6.40',
        min: 272.94,
        avg: 1587.395,
        max: 2901.85,
    },
    {
        ranking: '6.50',
        min: 2313.42,
        avg: 2511.58,
        max: 2709.74,
    },
    {
        ranking: '6.60',
        min: 2096.25,
        avg: 2250.8050000000003,
        max: 2405.36,
    },
    {
        ranking: '6.70',
        min: 481.73,
        avg: 1808.7450000000001,
        max: 3135.76,
    },
    {
        ranking: '6.80',
        min: 2423.86,
        avg: 2423.86,
        max: 2423.86,
    },
    {
        ranking: '6.90',
        min: 1621.6,
        avg: 2006.98,
        max: 2392.36,
    },
    {
        ranking: '7.00',
        min: 2067.34,
        avg: 2261.415,
        max: 2455.49,
    },
    {
        ranking: '7.10',
        min: 2368,
        avg: 2368,
        max: 2368,
    },
    {
        ranking: '7.20',
        min: 2099.79,
        avg: 2123.31,
        max: 2146.83,
    },
    {
        ranking: '7.30',
        min: 1844.14,
        avg: 2027.3650000000002,
        max: 2210.59,
    },
    {
        ranking: '7.40',
        min: 2006.44,
        avg: 2595.91,
        max: 3185.38,
    },
    {
        ranking: '7.50',
        min: 1007.32,
        avg: 1580.4650000000001,
        max: 2153.61,
    },
    {
        ranking: '7.60',
        min: 1963.36,
        avg: 2111.98,
        max: 2260.6,
    },
    {
        ranking: '7.70',
        min: 1806.33,
        avg: 1890.63,
        max: 1974.93,
    },
    {
        ranking: '7.80',
        min: 1645.85,
        avg: 2615.64,
        max: 3585.43,
    },
    {
        ranking: '7.90',
        min: 546.79,
        avg: 546.79,
        max: 546.79,
    },
    {
        ranking: '8.00',
        min: 1666.02,
        avg: 1789.005,
        max: 1911.99,
    },
    {
        ranking: '8.10',
        min: 1747.42,
        avg: 2109.29,
        max: 2471.16,
    },
    {
        ranking: '8.20',
        min: 1507.07,
        avg: 1827.4299999999998,
        max: 2147.79,
    },
    {
        ranking: '8.30',
        min: 2468.69,
        avg: 2468.69,
        max: 2468.69,
    },
    {
        ranking: '8.40',
        min: 1650.12,
        avg: 1731.505,
        max: 1812.89,
    },
    {
        ranking: '8.60',
        min: 1447.78,
        avg: 1668.23,
        max: 1888.68,
    },
    {
        ranking: '8.70',
        min: 1231.98,
        avg: 1231.98,
        max: 1231.98,
    },
    {
        ranking: '8.80',
        min: 1277.1,
        avg: 1464.115,
        max: 1651.13,
    },
    {
        ranking: '9.00',
        min: 1315.32,
        avg: 3600.035,
        max: 5884.75,
    },
    {
        ranking: '9.10',
        min: 1106,
        avg: 1809.38,
        max: 2512.76,
    },
    {
        ranking: '9.20',
        min: 1212.95,
        avg: 1487.225,
        max: 1761.5,
    },
    {
        ranking: '9.30',
        min: 1225.95,
        avg: 2004.375,
        max: 2782.8,
    },
    {
        ranking: '9.40',
        min: 1389.64,
        avg: 1454.46,
        max: 1519.28,
    },
    {
        ranking: '9.50',
        min: 1367.41,
        avg: 1438.38,
        max: 1509.35,
    },
    {
        ranking: '9.60',
        min: 1091.71,
        avg: 1369.6750000000002,
        max: 1647.64,
    },
    {
        ranking: '9.80',
        min: 1192.91,
        avg: 1270.545,
        max: 1348.18,
    },
    {
        ranking: '9.90',
        min: 738,
        avg: 1000.425,
        max: 1262.85,
    },
    {
        ranking: '10.00',
        min: 704.96,
        avg: 1325.005,
        max: 1945.05,
    },
    {
        ranking: '11.00',
        min: 506.76,
        avg: 1493.275,
        max: 2479.79,
    },
    {
        ranking: '12.00',
        min: 170.76,
        avg: 770.025,
        max: 1369.29,
    },
    {
        ranking: '13.00',
        min: 450,
        avg: 840.925,
        max: 1231.85,
    },
    {
        ranking: '14.00',
        min: 173.55,
        avg: 558.735,
        max: 943.92,
    },
    {
        ranking: '15.00',
        min: 230.14,
        avg: 771.7049999999999,
        max: 1313.27,
    },
    {
        ranking: '16.00',
        min: 74.95,
        avg: 996.355,
        max: 1917.76,
    },
    {
        ranking: '17.00',
        min: 326.7,
        avg: 827.85,
        max: 1329,
    },
    {
        ranking: '18.00',
        min: 366,
        avg: 811.85,
        max: 1257.7,
    },
    {
        ranking: '19.00',
        min: 104.98,
        avg: 722.34,
        max: 1339.7,
    },
];

export interface PerformerRankingDataPoint {
    ranking: string;
    min: number;
    avg: number;
    max: number;
}

const OnlyFansRankingsChartLinear = ({ data }: { data: PerformerRankingDataPoint[] }) => {
    const theme: Theme = useTheme();
    const chartRef = useRef<HTMLDivElement>(null);
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    useEffect(() => {
        const resizeObserver = new ResizeObserver(entries => {
            for (let entry of entries) {
                const { width, height } = entry.contentRect;
                setDimensions({ width, height });
            }
        });

        if (chartRef.current) {
            resizeObserver.observe(chartRef.current);
        }

        return () => {
            resizeObserver.disconnect();
        };
    }, []);

    useEffect(() => {
        if (chartRef.current && dimensions.width > 0 && dimensions.height > 0) {
            drawChart();
        }
    }, [dimensions]);

    const drawChart = (): void => {
        const margin = { top: 20, right: 30, bottom: 100, left: 65 };
        const width = dimensions.width - margin.left - margin.right;
        const height = dimensions.height - margin.top - margin.bottom;
        const brushHeight = 50;

        d3.select(chartRef.current).selectAll('svg').remove();

        const svg = d3
            .select(chartRef.current)
            .append('svg')
            .attr('width', width + margin.left + margin.right)
            .attr('height', height + margin.top + margin.bottom + brushHeight)
            .append('g')
            .attr('transform', `translate(${margin.left},${margin.top})`);

        // Parse ranking strings to numbers for scaling
        const rankingValues = data.map(d => parseFloat(d.ranking));

        // Changed to scaleLinear
        const x = d3
            .scaleLinear()
            .range([0, width])
            .domain([0, (d3.max(rankingValues) || 0.2) + 0.01]); // Add 5% extra space

        const y = d3
            .scaleLinear()
            .range([height, 0])
            .domain([0, (d3.max(data, d => d.max) || 0) * 1.1]); // Add 10% extra space

        const xAxis = d3.axisBottom(x).tickFormat(
            d =>
                `${d.toLocaleString(undefined, {
                    minimumFractionDigits: 2,
                    maximumFractionDigits: 2,
                })}%`,
        );
        const yAxis = d3.axisLeft(y).tickFormat(d => `$${d3.format('.2s')(d)}`);

        // Clip path
        svg.append('defs').append('clipPath').attr('id', 'clip').append('rect').attr('width', width).attr('height', height);

        // Main chart group
        const mainChart = svg.append('g').attr('clip-path', 'url(#clip)');

        // Add grid lines
        const gridGroup = mainChart.append('g').attr('class', 'grid-lines');

        function updateGrid() {
            gridGroup.selectAll('.grid-line').remove();
            const yTicks = y.ticks();
            gridGroup
                .selectAll('.grid-line')
                .data(yTicks.slice(1)) // Exclude the first tick
                .enter()
                .append('line')
                .attr('class', 'grid-line')
                .attr('x1', 0)
                .attr('x2', width)
                .attr('y1', d => y(d))
                .attr('y2', d => y(d))
                .attr('stroke', '#e0e0e0')
                .attr('stroke-width', 1);
        }

        const xAxisGroup = svg
            .append('g')
            .attr('class', 'x-axis')
            .style('font-size', '11px')
            .attr('transform', `translate(0,${height})`)
            .call(xAxis);

        const yAxisGroup = svg.append('g').attr('class', 'y-axis').style('font-size', '11px').call(yAxis);

        const minMaxLines = mainChart
            .selectAll('.minmax-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'minmax-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        const maxLines = mainChart
            .selectAll('.max-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'max-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        const minLines = mainChart
            .selectAll('.min-line')
            .data(data)
            .enter()
            .append('line')
            .attr('class', 'min-line')
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        // add ranking label above the max line
        const maxLabels = mainChart
            .selectAll('.max-label')
            .data(data)
            .enter()
            .append('text')
            .attr('class', 'max-label')
            .attr('text-anchor', 'middle')
            .attr('dy', '-1em')
            .attr('fill', 'black')
            .attr('font-size', '10px')
            .text(d => `${d.ranking}%`);

        const tooltip = d3
            .select(chartRef.current)
            .append('div')
            .attr('class', 'tooltip')
            .style('opacity', 0)
            .style('position', 'absolute')
            .style('background-color', 'white')
            .style('border', 'solid')
            .style('border-width', '1px')
            .style('border-radius', '5px')
            .style('padding', '10px');

        const avgDots = mainChart
            .selectAll('.avg-dot')
            .data(data)
            .enter()
            .append('circle')
            .attr('class', 'avg-dot')
            .attr('r', 3)
            .attr('fill', theme.palette.primary.main)
            .on('mouseover', (event: MouseEvent, d: PerformerRankingDataPoint) => {
                tooltip.transition().duration(200).style('opacity', 0.9);
                const tooltipWidth = 200; // Approximate width of the tooltip
                const tooltipHeight = 100; // Approximate height of the tooltip
                // const xPosition = x(d.ranking);
                // const yPosition = y(d.avg);

                let left = event.pageX + 10;
                let top = event.pageY - 28;

                // Adjust tooltip position if it would overflow the right side of the chart
                if (left + tooltipWidth > dimensions.width) {
                    left = event.pageX - tooltipWidth - 10;
                }

                // Adjust tooltip position if it would overflow the bottom of the chart
                if (top + tooltipHeight > dimensions.height) {
                    top = event.pageY - tooltipHeight - 10;
                }

                tooltip
                    .html(
                        `<table cellspacing="2" cellpadding="2" border="0">
                        <tr>
                            <td><strong>Ranking</strong></td>
                            <td><strong>${d.ranking}%</strong></td>
                        </tr>
                        <tr>
                            <td>Min:</td>
                            <td>$${d.min.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                        <tr>
                            <td>Avg:</td>
                            <td>$${d.avg.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                        <tr>
                            <td>Max:</td>
                            <td>$${d.max.toLocaleString(undefined, {
                                minimumFractionDigits: 0,
                                maximumFractionDigits: 0,
                            })}</td>
                        </tr>
                    </table>`,
                    )
                    .style('left', `${left}px`)
                    .style('top', `${top}px`);
            })
            .on('mouseout', () => {
                tooltip.transition().duration(500).style('opacity', 0);
            });

        // X-axis label
        svg.append('text')
            .attr('x', width / 2)
            .attr('y', height + margin.bottom - 60)
            .attr('text-anchor', 'middle')
            .style('font-size', '12px')
            .text('Ranking Percentage');

        // Y-axis label
        svg.append('text')
            .attr('transform', 'rotate(-90)')
            .attr('y', 0 - margin.left)
            .attr('x', 0 - height / 2)
            .attr('dy', '1em')
            .style('text-anchor', 'middle')
            .style('font-size', '12px')
            .text('Earnings per Month (Gross, USD)');

        // Brush
        const brush = d3
            .brushX()
            .extent([
                [0, 0],
                [width, brushHeight],
            ])
            .on('brush end', brushed);

        const context = svg
            .append('g')
            .attr('class', 'context')
            .attr('transform', `translate(0,${height + margin.top + 20})`);

        const xBrush = x.copy();
        const yBrush = d3.scaleLinear().range([brushHeight, 0]).domain(y.domain());

        // Add the brush overview area
        context
            .append('g')
            .selectAll('line')
            .data(data)
            .enter()
            .append('line')
            .attr('x1', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('x2', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('y1', (d: PerformerRankingDataPoint) => yBrush(d.min))
            .attr('y2', (d: PerformerRankingDataPoint) => yBrush(d.max))
            .attr('stroke', 'black')
            .attr('stroke-width', 1);

        context
            .append('g')
            .selectAll('circle')
            .data(data)
            .enter()
            .append('circle')
            .attr('cx', (d: PerformerRankingDataPoint) => xBrush(parseFloat(d.ranking)))
            .attr('cy', (d: PerformerRankingDataPoint) => yBrush(d.avg))
            .attr('r', 2)
            .attr('fill', theme.palette.primary.main);

        context
            .append('g')
            .attr('class', 'axis axis--x')
            .attr('transform', `translate(0,${brushHeight})`)
            .call(
                d3.axisBottom(xBrush).tickFormat(
                    d =>
                        `${d.toLocaleString(undefined, {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2,
                        })}%`,
                ),
            );

        context
            .append('g')
            .attr('class', 'brush')
            .call(brush)
            .call(brush.move, [0, xBrush(0.2)]);

        function updateChart(xDomain: [number, number]) {
            x.domain(xDomain);

            const visibleData = data.filter(d => {
                const rankingValue = parseFloat(d.ranking);
                return rankingValue >= xDomain[0] && rankingValue <= xDomain[1];
            });
            const yDomain = [0, (d3.max(visibleData, d => d.max) || 0) * 1.1];

            y.domain(yDomain);

            xAxisGroup.call(xAxis as any);
            yAxisGroup.call(yAxis as any);

            updateGrid(); // Update grid lines

            minMaxLines
                .attr('x1', d => x(parseFloat(d.ranking)))
                .attr('x2', d => x(parseFloat(d.ranking)))
                .attr('y1', d => y(d.min))
                .attr('y2', d => y(d.max));

            maxLines
                .attr('x1', d => x(parseFloat(d.ranking)) - 5)
                .attr('x2', d => x(parseFloat(d.ranking)) + 5)
                .attr('y1', d => y(d.max))
                .attr('y2', d => y(d.max));

            minLines
                .attr('x1', d => x(parseFloat(d.ranking)) - 5)
                .attr('x2', d => x(parseFloat(d.ranking)) + 5)
                .attr('y1', d => y(d.min))
                .attr('y2', d => y(d.min));

            maxLabels.attr('x', d => x(parseFloat(d.ranking))).attr('y', d => y(d.max));

            avgDots.attr('cx', d => x(parseFloat(d.ranking))).attr('cy', d => y(d.avg));
        }

        function brushed(event: d3.D3BrushEvent<unknown>) {
            if (event.selection) {
                // Get the selected range
                const [x0, x1] = event.selection as [number, number];
                const newXDomain: [number, number] = [xBrush.invert(x0), xBrush.invert(x1)];

                updateChart(newXDomain);
            }
        }
    };

    return <div ref={chartRef} style={{ width: '100%', height: '100%' }} />;
};

const RankingSelector = ({
    selectedRanking,
    setSelectedRanking,
    data,
}: {
    selectedRanking: number;
    setSelectedRanking: (ranking: number) => void;
    data: PerformerRankingDataPoint[];
}) => {
    const theme: Theme = useTheme();

    const handleChange = (event: Event, value: number | number[]) => {
        if (typeof value === 'number') {
            setSelectedRanking(value);
        }
    };

    return (
        <Box sx={{ width: '100%' }}>
            <Grid container spacing={2}>
                <Grid item xs={12}>
                    <Slider
                        value={selectedRanking}
                        onChange={handleChange}
                        valueLabelDisplay="auto"
                        step={0.1}
                        min={parseFloat(data[0].ranking)}
                        max={parseFloat(data[data.length - 1].ranking)}
                        marks={[
                            { value: 0, label: '0%' },
                            { value: 5, label: '5%' },
                            { value: 10, label: '10%' },
                            { value: 15, label: '15%' },
                            { value: 20, label: '20%' },
                        ]}
                    />
                </Grid>
                <Grid item xs={12}>
                    <Typography variant="body2" color="textSecondary">
                        Selected ranking: {selectedRanking.toFixed(2)}%
                    </Typography>
                </Grid>
            </Grid>
        </Box>
    );
};

const PerformerRanking = () => {
    const settingsContext = useContext(SettingsContext);
    const theme: Theme = useTheme();

    const { data: performerTop, isLoading: isPerformerTopLoading } = useSubscriberPerformerTop();
    const { data: subscriber, isLoading: isSubscriberLoading } = useSubscriber();

    const [myRanking, setMyRanking] = useState<number>(0);
    const [selectedRanking, setSelectedRanking] = useState<number>(0);

    useEffect(() => {
        if (performerTop && performerTop.performerTop < parseFloat(data[data.length - 1].ranking)) {
            setSelectedRanking(performerTop.performerTop);
            setMyRanking(performerTop.performerTop);
        } else {
            setSelectedRanking(0);
        }
    }, [performerTop]);

    return (
        <PageContainer>
            {subscriber && '_id' in subscriber && subscriber._id && (
                <>
                    <Typography variant="h5" sx={{ marginBottom: 2 }}>
                        <BackNavigationButton
                            url={`/subscribers/${subscriber._id || ''}/${settingsContext.services.messageUsers.homeUrl}`}
                        />{' '}
                        OnlyFans Performer Ranking
                    </Typography>

                    <PerformerRankingMyRankingCard myRanking={myRanking} isPerformerTopLoading={isPerformerTopLoading} data={data} />

                    <Card sx={{ width: '100%', marginBottom: 2 }}>
                        <CardContent>
                            <Grid container flexGrow={1} spacing={2} sx={{ marginBottom: 1 }}>
                                <Grid item xs={12}>
                                    <Typography variant="h5">Current Rankings</Typography>
                                    <Typography variant="caption" color="textSecondary">
                                        Updated {moment().format('LL')}
                                    </Typography>
                                    <Typography variant="body1" gutterBottom sx={{ mt: 1 }}>
                                        The following graph shows an approximation of what level of earnings a month is required to reach a
                                        certain ranking on OnlyFans.
                                    </Typography>
                                    <Typography variant="body1" gutterBottom>
                                        Please bear in mind that these are figures that are based on a sample of OnlyFans performers and may
                                        not be 100% accurate as we don't know exactly how OnlyFans calculates their rankings. But
                                        nevertheless, it should give you a rough idea of what you need to earn to reach a certain ranking.
                                    </Typography>
                                    <Typography variant="body1">
                                        Each data point below represents a ranking percentage (X axis), the minimum (lower horizontal line),
                                        average (blue dot), and maximum (upper horizontal line) earnings (Y axis) for a given ranking.
                                    </Typography>
                                </Grid>
                                <Grid item xs={12}>
                                    <RankingSelector
                                        selectedRanking={selectedRanking}
                                        setSelectedRanking={setSelectedRanking}
                                        data={data}
                                    />
                                </Grid>
                                <Grid item xs={12}>
                                    <Box sx={{ height: '500px' }}>
                                        <OnlyFansRankingsChartLinear data={data} />
                                    </Box>
                                </Grid>
                                <Grid item xs={12} textAlign="center">
                                    <Typography variant="body2" color="textSecondary">
                                        Use the slider above to zoom in on a specific ranking range.
                                    </Typography>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Card>
                </>
            )}
        </PageContainer>
    );
};

export default PerformerRanking;

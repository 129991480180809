import LoadingButton from '@mui/lab/LoadingButton';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    FormControl,
    Grid,
    InputLabel,
    MenuItem,
    Select,
    TextField,
} from '@mui/material';
import { useEffect, useState } from 'react';
import useSextforceMetricsTrialsCreate from '../../../../../hooks/useSextforceMetricsTrialsCreate';
import useSubscriber from '../../../../../hooks/useSubscriber';
import TextFieldClipboard from '../../../../common/TextFieldClipboard';

interface Props {
    open: boolean;
    onClose: () => void;
    trialsRefetch: () => void;
}

const SextforceMetricsTrialsCreateTrialDialog = (props: Props) => {
    const { open, onClose, trialsRefetch } = props;

    const { data: subscriber } = useSubscriber();

    const [name, setName] = useState<string>('');
    const [offerLimit, setOfferLimit] = useState<number>(0);
    const [offerExpiration, setOfferExpiration] = useState<number>(0);
    const [freeTrialDuration, setFreeTrialDuration] = useState<number>(7);
    const [trackingLink, setTrackingLink] = useState<string>('');

    const { createTrial, isCreateTrialLoading } = useSextforceMetricsTrialsCreate();

    const handleCreateCampaign = async () => {
        if (name.trim().length === 0) {
            return;
        }

        const newTrial = await createTrial(name, offerLimit, offerExpiration, freeTrialDuration);

        if (newTrial) {
            setName('');
            setTrackingLink(newTrial.payload?.url || '');
        }
    };

    useEffect(() => {
        if (open) {
            setName('');
            setTrackingLink('');
        } else {
            if (trackingLink) {
                trialsRefetch();
            }
        }
    }, [trialsRefetch, open]);

    return (
        <Dialog open={open} onClose={onClose}>
            <DialogTitle>Create New Free Trial Link</DialogTitle>
            <DialogContent>
                <Grid container flexGrow={1} spacing={2} alignItems="center">
                    <Grid item xs={12}>
                        <DialogContentText>
                            Creating the free trial link here and not on the OnlyFans website makes the system more accurate because it will
                            be aware of the new fre trial link from the start and won't need to wait for the next sync.
                        </DialogContentText>
                    </Grid>
                    {!trackingLink && (
                        <>
                            <Grid item xs={12}>
                                <Grid container flexGrow={1} spacing={1} alignItems="center">
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <TextField
                                                label="Name"
                                                value={name}
                                                disabled={isCreateTrialLoading}
                                                onChange={e => setName(e.target.value)}
                                                fullWidth
                                                required
                                                autoFocus
                                                error={name.trim().length === 0}
                                                helperText={name.trim().length === 0 ? 'Name is required' : ''}
                                            />
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="offerLimit">Offer Limit</InputLabel>
                                            <Select
                                                label="Offer Limit"
                                                value={offerLimit}
                                                disabled={isCreateTrialLoading}
                                                onChange={e => setOfferLimit(e.target.value as number)}
                                            >
                                                <MenuItem value={0}>No Limit</MenuItem>
                                                <MenuItem value={1}>1 Subscriber</MenuItem>
                                                <MenuItem value={2}>2 Subscribers</MenuItem>
                                                <MenuItem value={3}>3 Subscribers</MenuItem>
                                                <MenuItem value={4}>4 Subscribers</MenuItem>
                                                <MenuItem value={5}>5 Subscribers</MenuItem>
                                                <MenuItem value={6}>6 Subscribers</MenuItem>
                                                <MenuItem value={7}>7 Subscribers</MenuItem>
                                                <MenuItem value={8}>8 Subscribers</MenuItem>
                                                <MenuItem value={9}>9 Subscribers</MenuItem>
                                                <MenuItem value={10}>10 Subscribers</MenuItem>
                                                <MenuItem value={50}>50 Subscribers</MenuItem>
                                                <MenuItem value={100}>100 Subscribers</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="offerExpiration">Offer Expiration</InputLabel>
                                            <Select
                                                label="Offer Expiration"
                                                value={offerExpiration}
                                                disabled={isCreateTrialLoading}
                                                onChange={e => setOfferExpiration(e.target.value as number)}
                                            >
                                                <MenuItem value={0}>No Expiration</MenuItem>
                                                <MenuItem value={1}>1 Day</MenuItem>
                                                <MenuItem value={2}>2 Days</MenuItem>
                                                <MenuItem value={3}>3 Days</MenuItem>
                                                <MenuItem value={4}>4 Days</MenuItem>
                                                <MenuItem value={5}>5 Days</MenuItem>
                                                <MenuItem value={6}>6 Days</MenuItem>
                                                <MenuItem value={7}>7 Days</MenuItem>
                                                <MenuItem value={8}>8 Days</MenuItem>
                                                <MenuItem value={9}>9 Days</MenuItem>
                                                <MenuItem value={10}>10 Days</MenuItem>
                                                <MenuItem value={11}>11 Days</MenuItem>
                                                <MenuItem value={12}>12 Days</MenuItem>
                                                <MenuItem value={13}>13 Days</MenuItem>
                                                <MenuItem value={14}>14 Days</MenuItem>
                                                <MenuItem value={15}>15 Days</MenuItem>
                                                <MenuItem value={16}>16 Days</MenuItem>
                                                <MenuItem value={17}>17 Days</MenuItem>
                                                <MenuItem value={18}>18 Days</MenuItem>
                                                <MenuItem value={19}>19 Days</MenuItem>
                                                <MenuItem value={20}>20 Days</MenuItem>
                                                <MenuItem value={21}>21 Days</MenuItem>
                                                <MenuItem value={22}>22 Days</MenuItem>
                                                <MenuItem value={23}>23 Days</MenuItem>
                                                <MenuItem value={24}>24 Days</MenuItem>
                                                <MenuItem value={25}>25 Days</MenuItem>
                                                <MenuItem value={26}>26 Days</MenuItem>
                                                <MenuItem value={27}>27 Days</MenuItem>
                                                <MenuItem value={28}>28 Days</MenuItem>
                                                <MenuItem value={29}>29 Days</MenuItem>
                                                <MenuItem value={30}>30 Days</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <FormControl fullWidth>
                                            <InputLabel id="freeTrialDuration">Free Trial Duration</InputLabel>
                                            <Select
                                                label="Free Trial Duration"
                                                value={freeTrialDuration}
                                                disabled={isCreateTrialLoading}
                                                onChange={e => setFreeTrialDuration(e.target.value as number)}
                                            >
                                                <MenuItem value={1}>1 Day</MenuItem>
                                                <MenuItem value={3}>3 Days</MenuItem>
                                                <MenuItem value={7}>7 Days</MenuItem>
                                                <MenuItem value={14}>14 Days</MenuItem>
                                                <MenuItem value={30}>1 Month</MenuItem>
                                                <MenuItem value={90}>3 Months</MenuItem>
                                                <MenuItem value={180}>6 Months</MenuItem>
                                                <MenuItem value={360}>12 Months</MenuItem>
                                            </Select>
                                        </FormControl>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    {!trackingLink && (
                        <Grid item xs={12}>
                            <DialogContentText>The new link will be shown here after it's created.</DialogContentText>
                        </Grid>
                    )}
                    {trackingLink && (
                        <>
                            <Grid item xs={12}>
                                <DialogContentText>Click below to copy the new trial link.</DialogContentText>
                            </Grid>
                            <Grid item xs={12}>
                                <TextFieldClipboard value={trackingLink} label="Trial Link" />
                            </Grid>
                        </>
                    )}
                </Grid>
            </DialogContent>

            <DialogActions
                sx={{
                    borderTop: '1px solid rgba(0, 0, 0, 0.12)',
                }}
            >
                <Button
                    color="secondary"
                    size="medium"
                    onClick={() => {
                        onClose();
                    }}
                >
                    Close
                </Button>
                <LoadingButton
                    variant="contained"
                    color="primary"
                    size="medium"
                    loading={isCreateTrialLoading}
                    disabled={!subscriber || name.trim().length === 0}
                    onClick={() => {
                        handleCreateCampaign();
                    }}
                >
                    Create
                </LoadingButton>
            </DialogActions>
        </Dialog>
    );
};

export default SextforceMetricsTrialsCreateTrialDialog;

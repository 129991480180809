import EmailIcon from '@mui/icons-material/Email';
import LabelIcon from '@mui/icons-material/Label';
import PasswordIcon from '@mui/icons-material/Password';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { FormControl, Grid, IconButton, InputAdornment, Link, Stack, TextField, Typography } from '@mui/material';
import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import useSubscriber from '../../hooks/useSubscriber';

type Props = {
    loginMode: 'email' | 'advanced';
    setLoginMode: (loginMode: 'email' | 'advanced') => void;
    email: string;
    setEmail: (email: string) => void;
    password: string;
    setPassword: (password: string) => void;
    userAgent: string;
    setUserAgent: (userAgent: string) => void;
    cookie: string;
    setCookie: (cookie: string) => void;
    xBc: string;
    setXBc: (xBc: string) => void;
    loading: boolean;
};

const OnlyFansReloginEmailPassword = (props: Props) => {
    const {
        loginMode,
        setLoginMode,
        email,
        setEmail,
        password,
        setPassword,
        userAgent,
        setUserAgent,
        cookie,
        setCookie,
        xBc,
        setXBc,
        loading,
    } = props;
    const { data: subscriber } = useSubscriber();

    const isLoginWorking: boolean = true;

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword(show => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <Grid container alignItems={'center'} justifyContent={'center'} spacing={1} sx={{ mt: 1 }}>
            {loginMode === 'email' && isLoginWorking && (
                <>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <FormControl fullWidth>
                                <TextField
                                    id="email"
                                    type="email"
                                    label="Email"
                                    value={email}
                                    placeholder={subscriber?.email}
                                    disabled={loading}
                                    onChange={event => setEmail(event.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <EmailIcon color={'primary'} />
                                            </InputAdornment>
                                        ),
                                        autoComplete: 'new-password',
                                    }}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={0.5} alignItems="center">
                            <FormControl fullWidth>
                                <TextField
                                    id="password"
                                    type={showPassword ? 'text' : 'password'}
                                    label="Password"
                                    value={password}
                                    placeholder="Same as before"
                                    disabled={loading}
                                    onChange={event => setPassword(event.target.value)}
                                    fullWidth
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <PasswordIcon color={'primary'} />
                                            </InputAdornment>
                                        ),
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={handleClickShowPassword}
                                                    onMouseDown={handleMouseDownPassword}
                                                    edge="end"
                                                >
                                                    {showPassword ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                        autoComplete: 'new-password',
                                    }}
                                    sx={{
                                        '& .MuiInputBase-input': {
                                            paddingRight: '2.5rem',
                                        },
                                    }}
                                />
                            </FormControl>
                        </Stack>
                    </Grid>
                </>
            )}
            {loginMode === 'email' && !isLoginWorking && (
                <Grid item xs={12}>
                    <Typography variant="body1" color="error">
                        With apologies, the online relogin feature is currently disabled.{' '}
                        <RouterLink to="http://t.me/adamcrowe" target="_blank">
                            Please contact Admin via Telegram for assistance.
                        </RouterLink>
                    </Typography>
                </Grid>
            )}
            {loginMode === 'advanced' && (
                <>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="userAgent"
                                label="User-Agent"
                                value={userAgent}
                                required
                                onChange={event => setUserAgent(event.target.value)}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LabelIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="cookie"
                                label="Cookie"
                                value={cookie}
                                required
                                onChange={event => setCookie(event.target.value)}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LabelIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl fullWidth>
                            <TextField
                                id="xBc"
                                label="X-Bc"
                                value={xBc}
                                required
                                onChange={event => setXBc(event.target.value)}
                                fullWidth
                                InputProps={{
                                    startAdornment: (
                                        <InputAdornment position="start">
                                            <LabelIcon />
                                        </InputAdornment>
                                    ),
                                }}
                                autoComplete="off"
                            />
                        </FormControl>
                    </Grid>
                </>
            )}
            <Grid item xs={12} textAlign="right">
                <Typography variant="caption" color="textSecondary">
                    <Link onClick={() => setLoginMode(loginMode === 'email' ? 'advanced' : 'email')} sx={{ cursor: 'pointer' }}>
                        Switch to {loginMode === 'email' ? 'Advanced Mode' : 'Basic Mode'}
                    </Link>
                </Typography>
            </Grid>
        </Grid>
    );
};

export default OnlyFansReloginEmailPassword;
